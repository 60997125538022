import { RavenModal } from '@ravenpay/raven-bank-ui';
import { useContext } from 'react';

import { useLocation } from 'react-router-dom';
import ActionContext from '../../../context/ActionContext';
import { clearCache, setCookie } from '../../../utils/helper/Helper';
import { useDispatch } from 'react-redux';
import { SET_TOKEN } from '../../../redux/auth';
// import { LOGOUT } from '../../../routes/PrivateGuard';

const SessionEndModal = () => {
	// const location = useLocation();
	const dispatch = useDispatch();

	const location = useLocation();
	const actionCtx = useContext(ActionContext);
	//   const navigate = useNavigate();
	const urlSave = location.pathname.split('/')[1];

	const LOGOUT = async () => {
		setCookie('token', '', 0);
		setCookie('x-pos-key', '', 0);
		
	};

	const handleSectionExpired = () => {
		actionCtx?.setSessionExpire(false);
		clearCache();
		localStorage.clear();
		LOGOUT();
		dispatch(SET_TOKEN(''));
		// navigate("/login");
		window.location.replace(`/login?${urlSave}`);
	};

	return (
		<RavenModal
			btnColor={`deep-green-light`}
			onBtnClick={handleSectionExpired}
			// onBtnClick={handleClear}
			visble={actionCtx?.sessionExpire}
			btnLabel="Back to login"
			onClose={() => {
				// actionCtx?.setIsLogout(false);
			}}
			outerClose={true}
			dontShowClose={true}
			className={`session-expired-wrap-modal`}
		>
			<div
				style={{
					marginBottom: '1rem',
					marginTop: '1.5rem',
				}}
				className="session-expired-wrap"
				// style={{marginTop: ""}}
			>
				<p
					style={{
						fontWeight: '700',
						marginBottom: '.5rem',
						fontSize: '1.8rem',
					}}
					className="title"
				>
					Session Expired
				</p>
				<p className="text grey-white-color">
					We apologise for any inconvenience this may have caused, but we take
					your security very seriously. Please navigate back to our login page
					to re-enter your credentials and restart your session.
				</p>
			</div>
		</RavenModal>
	);
};

export default SessionEndModal;
