import { TOGGLE_MENU, ONSWATCH } from './actions/actionTypes';

interface GeneralState {
	isMenuOpen: boolean;
	bankbox_brand_color: string;
}

const initialState: GeneralState = {
	isMenuOpen: false,
	bankbox_brand_color: 'EA872D',
};

const generalReducer = (
	state: GeneralState = initialState,
	action: { type: string; payload?: any }
) => {
	switch (action.type) {
		case TOGGLE_MENU:
			return {
				...state,
				isMenuOpen: action.payload ?? !state.isMenuOpen,
			};
		case ONSWATCH:
			return {
				...state,
				isMenuOpen: action.payload ?? !state.isMenuOpen,
			};
		default:
			return state;
	}
};

export default generalReducer;
