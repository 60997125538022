import { HsvaColor, color } from './';

export const getContrastingColor = (str: string | HsvaColor) => {
	if (!str) {
		return '#ffffff';
	}
	const col = color(str);
	const yiq = (col.rgb.r * 299 + col.rgb.g * 587 + col.rgb.b * 114) / 1000;
	return yiq >= 128 ? '#000000' : '#ffffff';
};

export function getInverseColor(hexColor: string) {
	// Convert the hex color to its RGB components
	const r = parseInt(hexColor.slice(1, 3), 16);
	const g = parseInt(hexColor.slice(3, 5), 16);
	const b = parseInt(hexColor.slice(5, 7), 16);

	// Calculate the brightness using a common formula
	const brightness = (r * 299 + g * 587 + b * 114) / 1000;

	// Determine the text/icon color based on the brightness
	return brightness > 128 ? 'black' : 'white';
}
