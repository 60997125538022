import React, { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ActionContext = createContext<any>({
	sideMenuDrop: {
		one: false,   
		two: false,
		three: false,
		four: false,
	},
	toggleTheme: () => {},
	setSideMenuDrop: (param?: any) => {},
	isProfileCheck: false,
	setIsProfileCheck: (param?: any) => {},
  sessionExpire: false,
  setSessionExpire: (param?: any) => {},
  logoutModal: false,
  setLogoutModal: (param?: any) => {},
});

export function ActionContextProvider(props?: any) {
	const location = useLocation();
  const [isLogoutVal, setIslogoutVal] = useState(false);
  const [isSessionExpireVal, setSessionExpireVal] = useState(false);
	const [sideMenuDropVal, setSideMenuDropVal] = useState({
		// one: location.pathname.includes("/dashboard-bill-payment") ? true : false,
		// two:
		//   location.pathname.includes("/dashboard-payroll") ||
		//   location.pathname.includes("/dashboard-payment-links") ||
		//   location.pathname.includes("/dashboard-pos") ||
		//   location.pathname.includes("/dashboard-store") ||
		//   location.pathname.includes("/dashboard-invoice")
		//     ? true
		//     : false,
		// three:
		//   location.pathname.includes("/dasboard-finance-invoice") ||
		//   location.pathname.includes("/dasboard-loan") ||
		//   location.pathname.includes("/dasboard-overdraft")
		//     ? true
		//     : false,
		one: false,
		two: false,
		three: false,
		four: false,
	});

	function setSideMenuDropValFunc(param?: any) {
		if (param === 'one') {
			setSideMenuDropVal((prev) => {
				return {
					...prev,
					one: !sideMenuDropVal?.one,
					two: false,
					three: false,
					four: false,
				};
			});
		}

		if (param === 'two') {
			setSideMenuDropVal((prev) => {
				return {
					...prev,
					one: false,
					two: !sideMenuDropVal?.two,
					three: false,
					four: false,
				};
			});
		}
		if (param === 'three') {
			setSideMenuDropVal((prev) => {
				return {
					...prev,
					one: false,
					three: !sideMenuDropVal?.three,
					two: false,
					four: false,
				};
			});
		}

		if (param === 'four') {
			setSideMenuDropVal((prev) => {
				return {
					...prev,
					one: false,
					four: !sideMenuDropVal?.four,
					two: false,
					three: false,
				};
			});
		}
		if (param === 'close') {
			setSideMenuDropVal((prev) => {
				return { ...prev, one: false, two: false, three: false, four: false };
			});
		}
	}
	const [isProfileCheckVal, setIsProfileCheckVal] = useState(false);
	function setIsProfileCheckFunc(param?: any) {
		setIsProfileCheckVal(param);
	}

	function SessionExpirefunc(param: any) {
    setSessionExpireVal(param);
  }

	function logoutModalChange(param: any) {
    setIslogoutVal(param);
  }

	const context = {
		sideMenuDrop: sideMenuDropVal,
		setSideMenuDrop: setSideMenuDropValFunc,
		isProfileCheck: isProfileCheckVal,
		setIsProfileCheck: setIsProfileCheckFunc,
    sessionExpire: isSessionExpireVal,
    setSessionExpire: SessionExpirefunc,
    logoutModal: isLogoutVal,
    setLogoutModal: logoutModalChange,
	};

	return (
		<ActionContext.Provider value={context}>
			{props.children}
		</ActionContext.Provider>
	);
}

export default ActionContext;
