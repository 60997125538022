import { RavenModal } from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import './style/ChangeRequestStatusModal.css';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import { bankboxAPI } from '../../../../../redux/bankbox';

interface MyComponentProps {
	visible?: boolean;
	onClose?: () => void;
	onFinish?: () => void;
	details?: any;
}

const ChangeRequestStatusModal: React.FC<MyComponentProps> = ({
	details,
	onClose,
	onFinish,
	visible,
}) => {
	const statusList = ['Approve', 'Decline', 'Dispatch', 'Delivered'];
	const [selectedItem, setSelectedItem] = useState('');
	const dispatch = useDispatch();
	const { loading_fee } = useSelector((state: RootState) => state.bankbox);
	// 		0 = pending, 1 = approved, 2 = dispatched, 3 = delivered
	// [3:17 PM] 4 = declined
	const changeReqStatus = async () => {
		const obj = {
			id: details?.id,
			status:
				selectedItem === 'Approve'
					? '1'
					: selectedItem === 'Decline'
					? '4'
					: selectedItem === 'Delivered'
					? '3'
					: '2',
		};
		// console.log(obj);
		// console.log(details);

		const data = await dispatch(bankboxAPI.changeRequestStatus(obj as any));
		// console.log(data);
		if (data?.payload?.status === 'success') {
			setSelectedItem('');
			onFinish && onFinish();
		}
	};

	useEffect(() => {
		if (visible) {
			if (String(details?.status) === '4') {
				setSelectedItem('Decline');
			}
			if (String(details?.status) === '1') {
				setSelectedItem('Approve');
			}
			if (String(details?.status) === '2') {
				setSelectedItem('Dispatch');
			}
			if (String(details?.status) === '3') {
				setSelectedItem('Delivered');
			}
		}
	}, [visible]);

	const getStatus = (param: any) => {
		// let val;
		const value = param === 'Decline' ? '4' : param === 'Approve' ? '1' : param === 'Delivered' ? '3' : '2';
		return value;
	};

	return (
		<RavenModal
			className={`session-expired-wrap-modal`}
			onClose={() => {
				setSelectedItem('');
				onClose && onClose();
			}}
			visble={visible}
			onBtnClick={changeReqStatus}
			btnColor="deep-green-light"
			btnLabel="Change Status"
			disabled={getStatus(selectedItem) === String(details?.status)}
			loading={loading_fee}
		>
			<div className="change-request-status-modal-wrap">
				<p className="title">Change Status</p>
				{/* status box start */}
				<div className="status-select-box-wrap">
					{statusList?.map((chi, idx) => {
						return (
							<div
								className={`status-item grey-bg ${
									selectedItem === chi && 'status-item-active'
								}`}
								onClick={() => {
									if (selectedItem !== chi) {
										setSelectedItem(chi);
									}
								}}
							>
								<span>{chi}</span>
							</div>
						);
					})}
				</div>
				{/* status box end */}
			</div>
		</RavenModal>
	);
};

export default ChangeRequestStatusModal;
