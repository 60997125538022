import React from 'react';
import './AuthOneLayoutWrap.css';
import { icons } from '../../assets/icons';
import LegacySwitch from './LegacySwitch';

interface MyComponentProps {
	children?: React.ReactNode;
}

const AuthThreeLayoutWrap: React.FC<MyComponentProps> = ({ children }) => {
	return (
		<div className="auth-layout-wrap-three">
			<div className="wrap">{children}</div>
			{/* <div className="live_chat">{icons.live_chat}</div> */}
		</div>
	);
};

export default AuthThreeLayoutWrap;
