import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import { toast } from '@ravenpay/raven-bank-ui';
import {
	AuthState,
	ExportPayload,
	SettingsState,
	TransactionState,
	VerificationState,
} from './types';
import { logger } from '../services/logger';
import { setCollections, setTransactions, setTransfers } from './wallet';
import { formatSearch } from './transaction';
import {
	setAirtimeRecords,
	setBettingRecords,
	setDataRecords,
	setElectricityRecords,
} from './bills';

export const transactionFilter = createAsyncThunk(
	'/filter-transaction',
	async (
		payload: {
			startDate: string | undefined;
			endDate: string | undefined;
			filterValue: string | undefined;
			filterBy?: string | undefined;
		},
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`web/transactions_for_export${
					payload.startDate ? '?start_date=' + payload.startDate : ''
				}${payload.endDate ? '&end_date=' + payload.endDate : ''}${
					payload.filterValue ? '&filter_value=' + payload.filterValue : ''
				}${payload.filterBy ? '&filter_by=' + payload.filterBy : ''}`
			);

			logger.log(data, 'trx');
			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				thunkAPI.dispatch(
					setTransactions(formatSearch(data?.data?.data, 'transactions'))
				);

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const transferFilter = createAsyncThunk(
	'/transfer_filter',
	async (
		payload: {
			startDate: string | Date | undefined;
			endDate: string | Date | undefined;
			filterValue: string | undefined;
			filterBy?: string | undefined;
		},
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`web/transfers_for_export${
					payload.startDate ? '?start_date=' + payload.startDate : ''
				}${payload.endDate ? '&end_date=' + payload.endDate : ''}${
					payload.filterValue ? '&filter_value=' + payload.filterValue : ''
				}${payload.filterBy ? '&filter_by=' + payload.filterBy : ''}`
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				logger.log(data, 'got here');

				thunkAPI.dispatch(
					setTransfers(formatSearch(data?.data?.data, 'transfers'))
				);

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const collectionFilter = createAsyncThunk(
	'/filter-collections',
	async (
		payload: {
			startDate: string | undefined;
			endDate: string | undefined;
			filterValue?: string | undefined;
			filterBy?: string | undefined;
		},
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`web/collections_for_export${
					payload.startDate ? '?start_date=' + payload.startDate : ''
				}${payload.endDate ? '&end_date=' + payload.endDate : ''}${
					payload.filterValue && payload.filterBy
						? '&filter_value=' + payload.filterValue
						: ''
				}${payload.filterBy ? '&filter_by=' + payload.filterBy : ''}`
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				logger.log(data, 'got here');

				thunkAPI.dispatch(
					setCollections(formatSearch(data?.data?.data, 'collections'))
				);

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const bettingFilter = createAsyncThunk(
	'/filter-betting',
	async (
		payload: {
			startDate: string | undefined;
			endDate: string | undefined;
			filterValue: string | undefined;
			filterBy?: string | undefined;
		},
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`web/bet_records${
					payload.startDate ? '?start_date=' + payload.startDate : ''
				}${payload.endDate ? '&end_date=' + payload.endDate : ''}${
					payload.filterValue ? '&filter_value=' + payload.filterValue : ''
				}${payload.filterBy ? '&filter_by=' + payload.filterBy : ''}`
			);

			logger.log(data, 'trx');
			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				thunkAPI.dispatch(
					setBettingRecords(formatSearch(data?.data?.data, 'bet_records'))
				);

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const airtimeFilter = createAsyncThunk(
	'/filter-airtime',
	async (
		payload: {
			startDate: string | undefined;
			endDate: string | undefined;
			filterValue: string | undefined;
			filterBy?: string | undefined;
		},
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`/web/airtime/records${
					payload.startDate ? '?start_date=' + payload.startDate : ''
				}${payload.endDate ? '&end_date=' + payload.endDate : ''}${
					payload.filterValue ? '&filter_value=' + payload.filterValue : ''
				}${payload.filterBy ? '&filter_by=' + payload.filterBy : ''}`
			);

			logger.log(data?.data?.data?.airtime_records, 'trx');
			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				thunkAPI.dispatch(
					setAirtimeRecords(
						formatSearch(data?.data?.data?.airtime_records, 'airtime_records')
					)
				);

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const dataFilter = createAsyncThunk(
	'/filter-data',
	async (
		payload: {
			startDate: string | undefined;
			endDate: string | undefined;
			filterValue: string | undefined;
			filterBy?: string | undefined;
		},
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`/web/data/records${
					payload.startDate ? '?start_date=' + payload.startDate : ''
				}${payload.endDate ? '&end_date=' + payload.endDate : ''}${
					payload.filterValue ? '&filter_value=' + payload.filterValue : ''
				}${payload.filterBy ? '&filter_by=' + payload.filterBy : ''}`
			);

			logger.log(data, 'trx');
			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				thunkAPI.dispatch(
					setDataRecords(formatSearch(data?.data?.data, 'data_records'))
				);

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const electricityFilter = createAsyncThunk(
	'/filter-electricity',
	async (
		payload: {
			startDate: string | undefined;
			endDate: string | undefined;
			filterValue: string | undefined;
			filterBy?: string | undefined;
		},
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`/web/electricity/records${
					payload.startDate ? '?start_date=' + payload.startDate : ''
				}${payload.endDate ? '&end_date=' + payload.endDate : ''}${
					payload.filterValue ? '&filter_value=' + payload.filterValue : ''
				}${payload.filterBy ? '&filter_by=' + payload.filterBy : ''}`
			);

			logger.log(data, 'trx');
			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				thunkAPI.dispatch(
					setElectricityRecords(
						formatSearch(data?.data?.data, 'electricity_records')
					)
				);

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const cableFilter = createAsyncThunk(
	'/filter-cable',
	async (
		payload: {
			startDate: string | undefined;
			endDate: string | undefined;
			filterValue: string | undefined;
			filterBy?: string | undefined;
		},
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`/web/cable/records${
					payload.startDate ? '?start_date=' + payload.startDate : ''
				}${payload.endDate ? '&end_date=' + payload.endDate : ''}${
					payload.filterValue ? '&filter_value=' + payload.filterValue : ''
				}${payload.filterBy ? '&filter_by=' + payload.filterBy : ''}`
			);

			logger.log(data, 'trx');
			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				thunkAPI.dispatch(
					setElectricityRecords(formatSearch(data?.data?.data, 'cable_records'))
				);

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const initialState: any = {
	loading: false,
};

export const filtersSlice = createSlice({
	name: 'filters',
	initialState,

	reducers: {
		setProfile: (state, action) => {
			state.profile = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(transactionFilter.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(transactionFilter.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(transactionFilter.rejected, () => {
			return initialState;
		});

		builder.addCase(bettingFilter.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(bettingFilter.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(bettingFilter.rejected, () => {
			return initialState;
		});

		builder.addCase(collectionFilter.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(collectionFilter.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(collectionFilter.rejected, () => {
			return initialState;
		});

		builder.addCase(cableFilter.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(cableFilter.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(cableFilter.rejected, () => {
			return initialState;
		});

		builder.addCase(electricityFilter.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(electricityFilter.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(electricityFilter.rejected, () => {
			return initialState;
		});

		builder.addCase(dataFilter.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(dataFilter.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(dataFilter.rejected, () => {
			return initialState;
		});

		builder.addCase(airtimeFilter.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(airtimeFilter.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(airtimeFilter.rejected, () => {
			return initialState;
		});

		builder.addCase(transferFilter.pending, (state) => {
			state.loading = true;
			state.searchLoading = true;
		});
		builder.addCase(transferFilter.fulfilled, (state) => {
			state.loading = false;
			state.searchLoading = false;
		});
		builder.addCase(transferFilter.rejected, () => {
			return initialState;
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = filtersSlice.actions;

export default filtersSlice.reducer;
