import { RavenInputField, RavenModal } from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import '../styles/index.css';
import RootState from '../../../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import {
	capitalizeFirstLetter,
	formatDateTwo,
	reactSelectStyle,
} from '../../../../../utils/helper/Helper';
import { bankboxAPI } from '../../../../../redux/bankbox';

interface MyComponentProps {
	visible?: boolean;
	onClose?: () => void;
	onFinish?: () => void;
	detail?: any;
}

const EditLocationModal: React.FC<MyComponentProps> = ({
	detail,
	onClose,
	onFinish,
	visible,
}) => {
	const daysList = [
		{ label: 'Monday', value: 'Mon' },
		{ label: 'Tuesday', value: 'Tue' },
		{ label: 'Wednesday', value: 'Wed' },
		{ label: 'Thursday', value: 'Thu' },
		{ label: 'Friday', value: 'Fri' },
		{ label: 'Saturday', value: 'Sat' },
		{ label: 'Sunday', value: 'Sun' },
	];
	const [selectedDays, setSelectedDay] = useState<string[]>([]);
	const { all_states, all_lga } = useSelector(
		(state: RootState) => state.bankbox
	);
	const handleSelectFunc = (param: any) => {
    // console.log(selectedDays);
    if(selectedDays){
      if (param) {
			if (selectedDays?.includes(param)) {
				const newList = selectedDays?.filter((chi) => chi !== param);
				setSelectedDay(newList);
			} else {
				const newList = [...selectedDays, param];
				setSelectedDay(newList);
			}
		}
    }
    else{
      const newList = [ param];
      // console.log(newList);
      setSelectedDay(newList);
      // setSelectedDay()
    }
		
	};
	const formatState = (param: any) => {
		if (param?.length > 0) {
			const newList = param?.map((chi: any) => {
				return { label: chi, value: chi };
			});
			return newList;
		}
	};
	const dispatch = useDispatch();
	interface formComponent {
		state: any;
		lga: any;
		address: string;
		start_time: string;
		stop_time: string;
		days: string[];
	}
	const [details, setDetails] = useState<formComponent>({
		state: '',
		lga: '',
		address: '',
		start_time: '',
		stop_time: '',
		days: [],
	});
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	useEffect(() => {
		// console.log(detail);
		// console.log(all_lga);
		const formatAllDays = (param: any) => {
			if (param?.length > 0) {
				const capDays = param?.map((chi: any) => capitalizeFirstLetter(chi));
				return capDays;
			}
		};
		dispatch(bankboxAPI.getBankboxLgaByStates({ region: detail?.state }));
		setDetails((prev) => {
			return {
				...prev,
				state: { label: detail?.state, value: detail?.state },
				lga: { label: detail?.lga, value: detail?.lga },
				address: detail?.address,
				start_time: detail?.start_time,
				stop_time: detail?.stop_time,
			};
		});
		setSelectedDay(formatAllDays(detail?.pick_up_days));
	}, [visible]);

	const [loading, setLoading] = useState(false);
	const handleSubmit = async () => {
		const obj = {
			...details,
			state: details?.state?.value,
			lga: details?.lga?.value,
			days: selectedDays,
			id: detail?.id,
		};
		setLoading(true);
		// console.log(obj);
		const data = await dispatch(bankboxAPI.editLocationPickUp(obj as any));
		// console.log(data);

		if (data?.payload?.status === 'success') {
			setLoading(false);
			setDetails((prev) => {
				return {
					...prev,
					state: '',
					lga: '',
					address: '',
					start_time: '',
					stop_time: '',
					days: [],
				};
			});
			setSelectedDay([]);
			onFinish && onFinish();
			onClose && onClose();
		} else {
			setLoading(false);
		}
	};

	return (
		<RavenModal
			onBtnClick={() => {
				// console.log(detail);
				handleSubmit();
			}}
			btnLabel="Update Pickup Location"
			btnColor="deep-green-light"
			loading={loading}
			onClose={() => {
				onClose && onClose();
			}}
			visble={visible}
			className={`session-expired-wrap-modal`}
		>
			<div className="add-location-modal-index-wrap">
				<div className="title-text-box">
					<p className="title">Edit Pickup Location</p>
					<p className="text grey-white-color">
						Edit location for customer to pickup.
					</p>
				</div>
				{/* form box styart */}
				<div className="form-box">
					{/* two group start */}
					<div
						style={{ position: 'relative', zIndex: 10 }}
						className="two-group-box"
					>
						<form autoComplete="off" style={{ width: '100%' }} action="">
							<RavenInputField
								selectStyles={reactSelectStyle}
								color="deep-green-light"
								label="State * "
								type="select"
								placeholder="Select option"
								value={details?.state}
								onChange={(e: any) => {
									setDetails((prev) => {
										return { ...prev, state: e, lga: '' };
									});
									// if
									dispatch(
										bankboxAPI.getBankboxLgaByStates({ region: e.value })
									);
								}}
								selectOption={formatState(all_states)}
							/>
						</form>
						<form autoComplete="off" style={{ width: '100%' }} action="">
							{' '}
							<RavenInputField
								selectStyles={reactSelectStyle}
								color="deep-green-light"
								label="LGA* "
								type="select"
								placeholder="Select option"
								disabled={!details?.state}
								value={details?.lga}
								onChange={(e: any) => {
									setDetails((prev) => {
										return { ...prev, lga: e };
									});
								}}
								selectOption={formatState(all_lga)}
								// loadingSelect={true}
							/>
						</form>
					</div>
					{/* two group end */}
					<RavenInputField
						color="deep-green-light"
						label="Pickup Address"
						type="text"
						placeholder="Address"
						value={details?.address}
						onChange={handleChange}
						name={`address`}
					/>

					{/* select days syart */}
					<div className="select-available-days-box">
						<p className="label">Set Available Pickup Days</p>
						{/* days select start */}
						<div className="select-days">
							{daysList?.map((chi, idx) => {
								return (
									<div
										onClick={() => {
											handleSelectFunc(chi?.value);
										}}
										key={idx}
										className={`item-box ${
											selectedDays?.includes(chi?.value) && 'item-box-active'
										}`}
									>
										<p className="item">{capitalizeFirstLetter(chi?.value)}</p>
									</div>
								);
							})}
						</div>
						{/* days select end */}
					</div>
					{/* select days end */}

					{/* two group start */}
					<div className="two-group-box">
						<RavenInputField
							color="deep-green-light"
							label="Start time of day* "
							type="date"
							placeholder="Select option"
							value={details?.start_time}
							dateOptions={{
								enableTime: true,
								noCalendar: true,
								dateFormat: 'H:i', // Format for displaying time
								// Additional options can be added here
							}}
							onChange={(e: any) => {
								// setEnableTime(e);
								setDetails((prev) => {
									return { ...prev, start_time: formatDateTwo(e)?.time };
								});
								// console.log(formatDateTwo(e)?.time);
							}}
						/>
						<RavenInputField
							color="deep-green-light"
							label="End time of day* "
							type="date"
							placeholder="Select option"
							value={details?.stop_time}
							dateOptions={{
								enableTime: true,
								noCalendar: true,
								dateFormat: 'H:i',
								// enable:[ enableTimeFunc],
							}}
							onChange={(e: any) => {
								setDetails((prev) => {
									return { ...prev, stop_time: formatDateTwo(e)?.time };
								});
								// console.log(formatDateTwo(e)?.time);
							}}
							disabled={!details?.start_time}
						/>
					</div>
					{/* two group end */}
				</div>
				{/* form box end */}
			</div>
		</RavenModal>
	);
};

export default EditLocationModal;
