import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
// import bankBoxAxios from '../utils/banbox_axios';
import { getCookie, setCookie } from '../utils/helper/Helper';
import SessionEndModal from '../layout/dashboard/dashboardLayout/SessionExpired';
import { useDispatch, useSelector } from 'react-redux';
// import { SET_TOKEN } from '../redux/auth';
// import testaxios from 'axios';
import env from '../env';
// import { decrypt2 } from '../utils/encrypt';
import RootState from '../redux/types';
import instance from '../utils/banbox_axios';
import instanceTwo from '../utils/axios';
import ActionContext from '../context/ActionContext';

export const LOGOUT = async () => {
	setCookie('token', '', 0);
	setCookie('x-pos-key', '', 0);
};

const PrivateRoute = ({ children }: any) => {
	const API = env.bankbox_api;
	const [sessionModal, setSessionModal] = React.useState(false);
	const isLoggingOutRef = useRef(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { profile } = useSelector((state: RootState) => state.wallet);
	const actionCtx = useContext(ActionContext);

	const xposKey = profile?.merchantInfo?.bankbox_key;
	const denouncedKey = getCookie('denounce-app-key');

	useEffect(() => {
		if (denouncedKey?.trim() === xposKey?.trim()) {
			setCookie('denounce-bankbox', 'true', 300);
		} else {
			setCookie('denounce-bankbox', '', 0);
		}
	}, [xposKey]);

	// useEffect(() => {
	// 	const intercept = axios.interceptors.response.use(
	// 		async (response: any) => {
	// 			// console.log(response, 'jhgsfghjkls');

	// 			if (response?.data?.data.includes('invalid_token')) {
	// 				actionCtx?.setSessionExpire(true);
	// 				if (!isLoggingOutRef.current) {
	// 					isLoggingOutRef.current = true;

	// 					// Perform logout actions
	// 					// LOGOUT();
	// 					// dispatch(SET_TOKEN(''));
	// 					actionCtx?.setSessionExpire(true);
	// 					// Use isMountedRef to check if the component is still mounted before updating state
	// 					// setTimeout(() => {
	// 					// 	if (isLoggingOutRef.current) {
	// 					// 		setSessionModal(true);
	// 					// 	}
	// 					// }, 50);

	// 					return Promise.reject('Invalid token');
	// 				}
	// 			} else if (response?.data?.data === 'no authorization header parsed') {
	// 				// Handle other specific conditions if needed
	// 				// console.log('hgfdfghj--------');

	// 				return Promise.reject('No authorization header parsed');
	// 			} else {
	// 				return response;
	// 			}
	// 			console.log(response, '-----3333------');

	// 			// Return the response for other cases
	// 		},
	// 		(error) => {
	// 			// console.log(error);

	// 			// Handle other types of errors if needed
	// 			return Promise.reject(error);
	// 		}
	// 	);

	// 	return () => {
	// 		// Cleanup: Remove the interceptor when the component is unmounted
	// 		// axios.interceptors.response.eject(interceptor);
	// 		axios.interceptors.response.eject(intercept);
	// 	};
	// }, [dispatch]);

	const authenticated = getCookie('token');

	// useEffect(() => {
	// 	if (!authenticated) {
	// 		setSessionModal(true);
	// 	}
	// }, [authenticated]);

	instance.interceptors.response.use(
		async (response: any) => {
			if (response?.data?.data === 'no authorization header parsed') {
				// console.log(response, '-----');
				actionCtx?.setSessionExpire(true);
				return;
			}
			if (
				response?.data?.data === 'token expired' ||
				response?.data?.data === 'invalid token'
			) {
				actionCtx?.setSessionExpire(true);
				// console.log(response?.data?.data);
				// console.log("kjhgs");
				return;
			} else {
				if (response?.data?.status === 'fail') {
					// response.data.data && toast.error(response.data.data);
				}
			}
			// console.log(response, "----33333-----");

			return response?.data?.data === 'no authorization header parsed'
				? null
				: response;
		},
		async (error) => {
			// console.log(error), '----------------jhj----------------';

			return error;
		}
	);

	instanceTwo.interceptors.response.use(
		async (response: any) => {
			// console.log(response, "-----iuu----");

			if (response?.data?.data === 'no authorization header parsed') {
				// console.log(response, '-----');
				actionCtx?.setSessionExpire(true);
				return;
			}
			if (
				response?.data?.data === 'token expired' ||
				response?.data?.data === 'invalid token'
			) {
				actionCtx?.setSessionExpire(true);
				// console.log(response?.data?.data);
				// console.log("kjhgs");
				return;
			} else {
				if (response?.data?.status === 'fail') {
					// response.data.data && toast.error(response.data.data);
				}
			}
			// console.log(response, "----33333-----");

			return response?.data?.data === 'no authorization header parsed'
				? null
				: response;
		},
		async (error) => {
			// console.log(error), '----------------jhj----------------';

			return error;
		}
	);

	// return authenticated ? children : children;
	return authenticated ? children : window.location.replace(`/login`);

	// return (
	// 	<>

	// 		{children}
	// 		<SessionEndModal
	// 			onClick={() => {
	// 				LOGOUT();
	// 				setSessionModal(false);
	// 				window.location.replace('/login');
	// 			}}
	// 			onCancel={() => {
	// 				navigate('/login');
	// 				setSessionModal(false);
	// 			}}
	// 			visible={sessionModal}
	// 		/>
	// 	</>
	// );
};

export default PrivateRoute;
