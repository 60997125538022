import { RavenModal } from '@ravenpay/raven-bank-ui';
import React from 'react';
import { icons } from '../../../assets/icons';
import {
	formatNumWithComma,
	formatNumWithCommaNaira,
	symbol,
} from '../../../utils/helper/Helper';
import Copy from '../copyCheck';

import './style/index.css';
import CodePreview from '../codePreview/CodePreview';
import BadgeComponent from '../badge/BadgeComponent';

type Modal = {
	onClose: Function;
	visible: boolean;
	direction: string;
	title: string;
	amount: number;
	meta?: string;
	onClick: Function;
	directionTitle?: string;
	status?: string;
	content: {
		label: string;
		value: string;
		copy?: boolean;
		trim?: boolean;
		dontSHow?: boolean;
	}[];
};
const TransactionModal = ({
	onClose,
	visible,
	title,
	content,
	direction,
	amount,
	onClick,
	directionTitle,
	meta,
	status,
	...props
}: Modal) => {
	const [collapsed, setCollapsed] = React.useState(true);
	return (
		<RavenModal
			className={'trx-modal-main-wrapper'}
			onClose={() => {
				onClose();
				setCollapsed(true);
			}}
			visble={visible}
			onBtnClick={onClick as () => void}
			{...props}
		>
			<div className="transaction-details-modal details-modal-wrap ">
				<h5 className="details-modal-wrap__title">
					{title || 'Transfer Details'}
				</h5>

				<div className="details-modal-wrap__content">
					<div className="content__main">
						<div
							className={`main__account-details ${!collapsed && 'collapsed'} ${
								collapsed && 'not-collapsed'
							}`}
						>
							{direction && (
								<div className="account-details__item">
									<p>{directionTitle || 'Direction'}</p>
									<div>
										<div
											// onClick={() => {
											// 	console.log(direction);
											// }}
											className={`badge badge--${direction}`}
										>
											<figure>
												{icons[`arrow_narrow_${direction.toLowerCase()}`]}
											</figure>
											<p>
												{direction?.slice(0, 1).toUpperCase() +
													direction?.slice(1, 10)}
											</p>
										</div>
									</div>
								</div>
							)}
							{content?.map((chi, idx) => {
								if (chi?.dontSHow) {
									return <React.Fragment key={idx}></React.Fragment>;
								}
								return (
									<div key={idx} className="account-details__item">
										<p>{chi?.label}</p>
										<span className="item__value">
											<p>
												{chi?.trim
													? chi?.value?.length > 20
														? chi?.value?.slice(0, 20) + '...'
														: chi?.value
													: chi.value
													? chi.value
													: '--'}
											</p>
											{chi?.value?.length > 5 && chi.copy && (
												<figure>{<Copy text={chi.value} />}</figure>
											)}
										</span>
									</div>
								);
							})}
							{status && (
								<>
									<div className="account-details__item">
										<p>{`Status`}</p>
										<div>
											<BadgeComponent
												className={`${status}`}
												text={status === 'success' ? `Success` : `Failed`}
											/>
										</div>
									</div>
								</>
							)}
						</div>
						{meta && (
							<div
								className={`meta_preview_wrapper ${!collapsed && 'expanded'}`}
							>
								<div className={`meta_preview ${collapsed && 'collapsed'}`}>
									<CodePreview json={JSON.parse(meta)} />
								</div>
							</div>
						)}

						<div className="trx-modal__button-wrap">
							{meta && (
								<button onClick={() => setCollapsed(!collapsed)}>
									<p>{collapsed ? 'View MetaData' : 'Collapse MetaData'}</p>
									<figure>{icons.code}</figure>
								</button>
							)}
							{/* <button>
								<p>Download File</p>
								<figure>{icons.export}</figure>
							</button> */}
							{/* <button>
								<p>Share</p>
								<figure>{icons.share_outline}</figure>
							</button> */}
						</div>
					</div>
				</div>
			</div>
		</RavenModal>
	);
};

export default TransactionModal;
