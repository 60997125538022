import React from 'react';
import './style/onboardindSteps.css';
import { icons } from '../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from '@ravenpay/raven-bank-ui';
function MobileOnboardingSteps({
	merchantInfo,
	businessInfo,
	clearance,
}: {
	merchantInfo: any;
	businessInfo: any;
	clearance: string;
}) {
	function Rails({ completed }: { completed: boolean }) {
		return (
			<div className={`verification-rails ${completed && 'completed'}`}>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
				<p></p>
			</div>
		);
	}

	const navigate = useNavigate();

	const [status, setStatus] = React.useState({
		bvn: merchantInfo?.bvn === 3 ? 'verified' : 'pending',
		id:
			businessInfo?.document_verification_status === 1 ? 'verified' : 'pending',
		cac:
			businessInfo?.registration_number?.length > 1
				? 'review'
				: businessInfo?.cac_verified === 1
				? 'verified'
				: 'pending',
		biz:
			businessInfo?.industry?.length > 1 &&
			businessInfo?.transaction_range?.length > 1
				? 'verified'
				: 'pending',
		address:
			businessInfo?.address_status === 1
				? 'verified'
				: businessInfo?.business_address?.length > 1
				? 'review'
				: 'pending',
	});

	React.useEffect(() => {
		setStatus({
			bvn: merchantInfo?.bvn === 3 ? 'verified' : 'pending',
			id:
				businessInfo?.document_verification_status === 1
					? 'verified'
					: 'pending',
			cac:
				businessInfo?.registration_number?.length > 1
					? 'review'
					: businessInfo?.cac_verified === 1
					? 'verified'
					: 'pending',
			biz:
				businessInfo?.industry?.length > 1 &&
				businessInfo?.transaction_range?.length > 1
					? 'verified'
					: 'pending',
			address:
				businessInfo?.address_status === 1
					? 'verified'
					: businessInfo?.business_address?.length > 1
					? 'review'
					: 'pending',
		});
	}, [businessInfo, merchantInfo]);

	const verification_steps = [
		{
			title: 'BVN Verification',
			subTitle: 'Swiftly validate your BVN, usually takes less than 1 minute.',
			status: status.bvn,
			link: '/verification?kyc-stage=bvn',
		},
		{
			title: 'ID/NIN Verification',
			subTitle: 'Verify your identification document, i.e NID, Passport',
			status: status.id,
			link: '/verification?kyc-stage=id',
		},
		{
			title: 'CAC Verification',
			subTitle: 'Enroll your business details using a registered RC Number',
			status: status.cac,
			link: '/verification?kyc-stage=cac',
		},
		{
			title: 'Business Details',
			subTitle: "Let's know more about your business, i.e industry.",
			status: status.biz,
			link: '/verification?kyc-stage=business-info',
		},
		{
			title: 'Address Verification',
			subTitle: 'We are interested in your business location.',
			status: status.address,
			link: '/verification?kyc-stage=business-address',
		},
	];

	// create a filter for verification_steps where status = verified then make them appear fist in the array
	let filtered_steps = verification_steps
		.filter((step) => step.status === 'verified')
		.concat(verification_steps.filter((step) => step.status !== 'verified'));

	return (
		<div className="mobile-onboarding-steps ">
			<div className="mobile-onboarding-steps__complete-your-kyc">
				<h5>Complete your KYC</h5>
			</div>
			{filtered_steps.slice(0, filtered_steps.length - 1).map((chi, idx) => {
				return (
					<div key={idx} className="mobile-onboarding-steps__items">
						<div className="items__rails">
							<figure>
								{chi.status === 'verified'
									? icons.info_checked
									: icons.info_orange}
							</figure>{' '}
							<Rails completed={chi.status === 'verified'} />
						</div>

						<div
							onClick={() => {
								clearance === 'admin'
									? navigate(chi.link)
									: toast.error(
											'Sorry, you are not allowed to access this resource.'
									  );
							}}
							className="items__content"
						>
							{' '}
							<h5>{chi.title}</h5>
							<small>{chi.subTitle}</small>
							<span className={chi.status}>
								<p>{chi.status === 'review' ? 'Ongoing Review' : chi.status}</p>
							</span>
						</div>
					</div>
				);
			})}
			<div className="mobile-onboarding-steps__items">
				<div className="items__rails">
					<figure>
						{filtered_steps[5]?.status === 'verified'
							? icons.info_checked
							: icons.info_orange}{' '}
						{/* <Rails /> */}
					</figure>
				</div>

				<div
					onClick={() => {
						navigate(filtered_steps.slice(-1)[0]?.link);
					}}
					className="items__content"
				>
					{' '}
					<h5>{filtered_steps.slice(-1)[0]?.title}</h5>
					<small>{filtered_steps.slice(-1)[0]?.subTitle}</small>
					<span className={filtered_steps.slice(-1)[0]?.status}>
						<p>
							{filtered_steps.slice(-1)[0]?.status === 'review'
								? 'Ongoing Review'
								: filtered_steps.slice(-1)[0]?.status}
						</p>{' '}
					</span>
				</div>
			</div>
		</div>
	);
}

export default MobileOnboardingSteps;
