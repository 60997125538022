import './style/index.css';
import { useState } from 'react';
import { Icons } from './icons';
import { useCamera } from './useCamera';
import { ScreenTwo } from './screen-two';
import Tesseract from 'tesseract.js';
import ID_IMAGE from './id_image.png';
import { RavenButton } from '@ravenpay/raven-bank-ui';

interface WebcamCaptureProps {
	onCapture?(src?: string): void;
	onCancel?(src?: string): void;
	onFinish?(src?: string): void;
}

const WebcamCapture = (_props: WebcamCaptureProps) => {
	const { captureImage } = useCamera();
	const [snappedImage, setSnappedImage] = useState<string>();
	const [step, setStep] = useState(1);
	const [scanResult, setScanResult] = useState<Tesseract.RecognizeResult>();
	const [onScan, setOnScan] = useState(false);

  const noteIcon = (
		<svg
			className="img"
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.464 20.0163C15.7399 20.0163 20.0168 15.7394 20.0168 10.4635C20.0168 5.1876 15.7399 0.910645 10.464 0.910645C5.18809 0.910645 0.911133 5.1876 0.911133 10.4635C0.911133 15.7394 5.18809 20.0163 10.464 20.0163Z"
				fill="#EA872D"
			/>
			<path
				d="M10.464 6.64235V10.4635M10.464 14.2846H10.4735M20.0168 10.4635C20.0168 15.7394 15.7399 20.0163 10.464 20.0163C5.18809 20.0163 0.911133 15.7394 0.911133 10.4635C0.911133 5.1876 5.18809 0.910645 10.464 0.910645C15.7399 0.910645 20.0168 5.1876 20.0168 10.4635Z"
				stroke="#FFF6ED"
				strokeWidth="1.43293"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const handleImageSnap = async () => {
		const res = await captureImage();
		// console.log(res);
		setSnappedImage(res);
	};

	const redo = () => {
		setStep(1);
		setScanResult(undefined);
		setSnappedImage(undefined);
		setOnScan(false);
	};

	return (
		<>
			<div className="camera-wrap-box">
				<div className="webcam-wrapper">
					{/* { */}
					<div id="video-player">
						<video playsInline />
						<canvas style={{ opacity: 0, width: '100%', height: '100%' }} />
						{/* {false && (
						<figure className="capture-id-mask">
							<img src={ID_IMAGE} alt="" />
						</figure>
					)} */}
						{/* <div className="camera-controls">
							<button onClick={handleImageSnap}>{Icons.Shutter}</button>
						</div> */}
					</div>
					{/* } */}
					{snappedImage && step === 1 && (
						<ScreenTwo
							image={snappedImage}
							onRetake={() => {
								setSnappedImage(undefined);
								setStep(1);
							}}
							onScanComplete={(res) => {
								setStep(2);
								setScanResult(res);
							}}
							scanning={false}
							onScan={onScan}
							onRedo={redo}
							onFinish={(param) => {
								_props?.onFinish && _props?.onFinish(param);
							}}
						/>
					)}
					{scanResult && step === 2 && (
						<div className="sdk-scanned-result">
							{scanResult.data.lines.map((res) => {
								if (res.confidence < 30) return <del>Text: {res.text}</del>;

								return (
									<p className="p" key={res.text}>
										Text: {res.text}
									</p>
								);
							})}

							<button onClick={redo}>Redo</button>
						</div>
					)}
				</div>
			</div>
			<div className="capture-note-box grey-bg-two">
				<div className="img-note">
					<figure className="img-box">{noteIcon}</figure>
				</div>
				<p className="note grey-white-color">
					Point your terminal to the camera and ensure to capture a clear image
					of the <span>Serial Number</span> at the back of the terminal
				</p>
			</div>
			<div className="two-btn-box">
				<RavenButton
					color="deep-green-light"
					label={
						step === 1 && !snappedImage
							? 'Cancel'
							: snappedImage
							? `Retake`
							: `Cancel`
					}
					className="cancel-btn"
					onClick={() => {
						if (step === 1 && !snappedImage) {
							_props?.onCancel && _props?.onCancel();
						}
						if (step === 1 && snappedImage) {
							setSnappedImage(undefined);
							setStep(1);
							redo();
							// setOnScan(false);
						}
						if (step === 2) {
							setSnappedImage(undefined);
							setStep(1);
							redo();
							// setOnScan(false);
						}
					}}
					// onClick={_props?.onCancel}
				/>
				<RavenButton
					color="deep-green-light"
					label={
						step === 1 && !snappedImage
							? 'Capture'
							: snappedImage
							? `Scan`
							: `Scan`
					}
					className="procced-btn"
					onClick={() => {
						if (step === 1 && !snappedImage) {
							handleImageSnap();
						}
						if (step === 1 && snappedImage) {
							setOnScan(true);
						}
					}}
				/>
			</div>
		</>
	);
};

export default WebcamCapture;
