import BankBoxOverview from '../../../pages/dashboard/bankbox';
import BankBoxUsers from '../../../pages/dashboard/bankbox/users';
import BankboxRequest from '../../../pages/dashboard/bankbox/components/request';
import BankboxTerminals from '../../../pages/dashboard/bankbox/terminals';
import BankBoxTransactions from '../../../pages/dashboard/bankbox/transactions';
import BankboxSettings from '../../../pages/dashboard/bankbox/settings';
import SingleViewBankbox from '../../../pages/dashboard/bankbox/terminals/component/SingleViewBankbox';
import Onboarding from '../../../pages/dashboard/onboarding';
import DisputeIndexPage from '../../../pages/dashboard/bankbox/disputes';
import FeeDetailsIndexPage from '../../../pages/dashboard/bankbox/fee-details';
import BankBoxUserPreview from '../../../pages/dashboard/bankbox/users/component/UserPreview';
import SingleBankBoxTransactions from '../../../pages/dashboard/bankbox/transactions/SingleTerminalTransactions';

export const overview_routes_group = [
	{
		path: '/dashboard-overview',
		element: BankBoxOverview,
	},
	{
		path: '/dashboard-bankbox/users',
		element: BankBoxUsers,
	},

	{
		path: '/dashboard-bankbox/user-single',
		element: BankBoxUserPreview,
	},
	{
		path: '/dashboard-onboarding',
		element: Onboarding,
	},
	{
		path: '/dashboard-bankbox/requests',
		element: BankboxRequest,
	},

	{
		path: '/dashboard-bankbox/terminals',
		element: BankboxTerminals,
	},
	{
		path: '/dashboard-bankbox/terminals/view',
		element: SingleViewBankbox,
	},
	{
		path: '/dashboard-bankbox/transactions',
		element: BankBoxTransactions,
	},
	{
		path: '/dashboard-bankbox/transaction-single',
		element: SingleBankBoxTransactions,
	},
	{
		path: '/dashboard-bankbox/settings',
		element: BankboxSettings,
	},
	{
		path: '/dashboard-bankbox/disputes',
		element: DisputeIndexPage,
	},
	{
		path: '/dashboard-bankbox/fee-details',
		element: FeeDetailsIndexPage,
	},
];
