import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import EmptyScreen from '../../../../components/common/emptyScreen';
import './style/FeeDetailIndexPage.css';
import SmartFilter from '../../../../components/common/smartFilter';
import Pagination from '../../../../components/common/pagination';
import {
	RavenButton,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import { icons } from '../../../../assets/icons';
import CreateFeeCategoryModal from './CreateFeeCategoryModal';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../redux/bankbox';
import RootState from '../../../../redux/types';
import {
	capitalizeFirstWord,
	formatDateTime,
	formatNumWithComma,
	formatTypeFunction,
	symbol,
} from '../../../../utils/helper/Helper';
import { debounce } from 'lodash';
import ExportModal from '../../../../components/common/exportModal';

const FeeDetailsIndexPage = () => {
	const [noContent, setNoContent] = useState(true);
	type showModalProp = {
		create: boolean;
		export: boolean;
	};
	const [currentPage, setCurrentPage] = useState(1);
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
	});
	const dispatch = useDispatch();
	const [refreshTable, setRefreshTable] = useState(false);
	const { all_fees, loading } = useSelector(
		(state: RootState) => state.bankbox
	);

	useEffect(() => {
		getFirstFetch();
	}, [refreshTable, currentPage]);

	const getFirstFetch = async () => {
		const data = await dispatch(
			bankboxAPI.getAllFees({
				per_page: 20,
				page: currentPage,
				// affiliate_app_id:
			})
		);

		if (
			data?.payload?.status === 'success' &&
			data?.payload?.data?.data?.data?.length > 0
		) {
			setNoContent(false);
		}
		// console.log(data);
	};

	const handleFinish = () => {
		setRefreshTable(!refreshTable);
		setShowModal((prev) => {
			return { ...prev, create: false };
		});
	};

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="Manage Fees on your terminals"
					pageTitle="Fee Details"
					topRightContent={
						noContent ? (
							<></>
						) : (
							<>
								<RavenButton
									size="small"
									onClick={() => {
										setShowModal((prev) => {
											return { ...prev, create: true };
										});
									}}
									color="deep-green-light"
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '.4rem',
										}}
										className="button__wrap"
									>
										<span>Create Fee </span>
										<figure>{icons.plus_circle_white}</figure>
									</div>
								</RavenButton>
							</>
						)
					}
				>
					{noContent ? (
						<>
							<EmptyScreen
								loading={loading}
								button={true}
								btnIcon={false}
								title="No Fee Details recorded yet"
								subTitle="Fees created on the platform get to show up here. There are none for now."
								buttonLabel="Create Fee Detail"
								onClick={() => {
									setShowModal((prev) => {
										return { ...prev, create: true };
									});
								}}
							/>
						</>
					) : (
						<>
							{/* dispute content start */}
							<div
								style={{ marginBottom: '1.5rem' }}
								className="global-search-pagination-filter-box"
							>
								{' '}
								<SmartFilter
									hideFilter
									// hideExport
									searchTitle="Search by category"
									defaultFilterBy="status"
									// filters={[
									// 	{
									// 		label: 'Status',
									// 		filterBy: 'status',
									// 		options: [
									// 			{
									// 				label: 'Pending',
									// 				value: 'pending',
									// 			},
									// 			{
									// 				label: 'Failed',
									// 				value: 'failed',
									// 			},
									// 			{
									// 				label: 'Successful',
									// 				value: 'succesful',
									// 			},
									// 		],
									// 	},
									// ]}
									onExport={() => {
										setShowModal((prev) => {
											return { ...prev, export: true };
										});
									}}
									page="bankbox_transactions"
									onSearchChange={debounce((e) => {
										dispatch(
											bankboxAPI.getAllFees({
												per_page: 20,
												page: currentPage,
												search: e,
											})
										);
									}, 500)}
								/>
								<div className="pagination-wrap">
									{' '}
									<Pagination
										className="top-bar__pagination"
										currentPage={currentPage}
										itemsPerPage={all_fees?.pagination?.per_page}
										totalItems={all_fees?.pagination?.total}
										onPageChange={function (page: number): void {
											setCurrentPage(page);
										}}
									/>
								</div>
							</div>
							<div
								// onClick={() => {
								// 	console.log(all_fees);
								// }}
								className="fee-detail-dashboard-content-index-wrap"
							>
								{/* table content start */}
								<div className="transaction__table non-mobile">
									<RavenTable
										action={false}
										className="table__main"
										headerList={[
											'FEE CATEORY',
											'FEE',
											'CAP',
											'MANDATORY FEE',
											'TRF FEE',
											'COLLECTION FEE',
											'DATE ADDED',
										]}
									>
										{all_fees?.data?.map((chi: any, idx: any) => {
											const {
												transfer_fee,
												created_at,
												reference,
												cap,
												bank_collection_fee,
												mandatory_fee,
											} = chi || {};
											return (
												<RavenTableRow
													loading={loading}
													one={capitalizeFirstWord(formatTypeFunction(reference) as any) || '----'}
													two={
														symbol('ngn') + formatNumWithComma(chi?.fee, 'ngn')
													}
													three={symbol('ngn') + formatNumWithComma(cap, 'ngn')}
													key={idx}
													four={
														symbol('ngn') +
														formatNumWithComma(transfer_fee, 'ngn')
													}
													five={
														symbol('ngn') +
														formatNumWithComma(bank_collection_fee, 'ngn')
													}
													six={
														symbol('ngn') +
														formatNumWithComma(mandatory_fee, 'ngn')
													}
													seven={formatDateTime(created_at)}
													// manualActionIcon={() => {

													// }}
												/>
											);
										})}
									</RavenTable>
								</div>
								{/* table content end */}
							</div>
							{/* dispute content end */}
						</>
					)}
				</PageLayout>
			</DashboardLayout>
			{/* modal start */}
			<CreateFeeCategoryModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, create: false };
					});
				}}
				visible={showModal?.create}
				onFinish={handleFinish}
			/>
			{/* modal end */}
			<ExportModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, export: false };
					});
				}}
				visible={showModal?.export}
				page="fees"
			/>
		</>
	);
};

export default FeeDetailsIndexPage;
