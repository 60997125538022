import React, { useState, useEffect } from 'react';
import VerficationLayout from '../../../layout/dashboard/verification';
import './styles/index.css';
import IDVerification from './component/id';
import CACVerification from './component/cac';
import BusinessInformation from './component/businessInformation';
import AddressVerification from './component/addressVerification/cac';
import { useLocation } from 'react-router-dom';
import BVNVerification from './component/bvn';

const Verification = () => {
	const [stage, setStage] = useState<string | undefined>('address');

	const location = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const kyc = searchParams.get('kyc-stage');

		// Use the paramValue as needed
		setStage(kyc ?? 'id');
	}, [location.search]);

	return (
		<VerficationLayout>
			{stage == 'id' && <IDVerification />}
			{stage == 'cac' && <CACVerification />}
			{stage == 'business-info' && <BusinessInformation />}
			{stage == 'address' && <AddressVerification />}
			{stage == 'bvn' && <BVNVerification />}
		</VerficationLayout>
	);
};

export default Verification;
