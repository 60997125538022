import React from "react";
import "./TitleTextBox.css";
import { FaAngleLeft } from "react-icons/fa";

interface MyComponentProps {
  children?: React.ReactNode;
  // children:
  title?: string;
  text?: string;
  dontShow?: boolean;
  back?: boolean;
  onBack?: () => void;
}

const TitleTextBox: React.FC<MyComponentProps> = ({
  title,
  text,
  children,
  dontShow,
  back,
  onBack,
}) => {

    if(dontShow){
        return<></>
    }

  return (
    <div className="title-text-index-box-wrap">
        <div className="back-text-box">
        {/* back icon start */}
        {back && ( 
        <div
          onClick={() => {
           onBack && onBack()
          }}
          className="back-box no-border-border"
        >
          <figure className="img-box">
            <FaAngleLeft style={{color: "white"}} className="img" />
          </figure>
          {/* <FaAngleLeft className="icon" /> */}
        </div>
         )} 
        {/* back icon end */}
        {/* text box start */}
        <div className="text-box">
          <p className="title">{title}</p>
          <p className="text grey-white-color">{text}</p>
        </div>
        {/* text box end */}
      </div>
      <div className="title-text-box">
        {/* <p className="title">{title || "Nothing to see here for now..."}</p> */}
        {/* <p className="text grey-white-color">{text}</p> */}
      </div>
      {/* children box start */}
      <div className="children-box">{children}</div>
      {/* children box end */}
    </div>
  );
};

export default TitleTextBox;
