import { RavenButton, RavenModal } from '@ravenpay/raven-bank-ui';
import React from 'react';
import './styles/index.css';

type DeleteModalProp = {
	visible: boolean;
	text?: string;
	title?: string;
	onCancel?: () => void;
	onDelete?: () => void;
	loading?: boolean;
	deleteText?: string;
	cancelText?: string;
	buttonColor?: any;
};
const DeleteModal = ({
	visible,
	text,
	title,
	onCancel,
	onDelete,
	loading,
	deleteText,
	buttonColor,
	cancelText,
}: DeleteModalProp) => {
	return (
		<RavenModal
			onBtnClick={() => {}}
			className={`delete-modal-reuse-wrap`}
			visble={visible}
			effect={`fadeInRight`}
		>
			<div className="container-wrap">
				<p className="title">{title || 'Delete Link'}</p>
				<p className="text grey-white-color">
					{text ||
						'Remove this beneficiaries, from your beneficiaries list, this mean you won’t be able to perform transaction, '}
				</p>
			</div>
			<div className="two-button-wrap">
				<p onClick={onCancel} className="cancel">
					{cancelText || 'Cancel'}
				</p>
				<RavenButton
					color={buttonColor || `error-light`}
					label={deleteText || 'Delete'}
					loading={loading}
					size={`small`}
					onClick={onDelete}
				/>
			</div>
		</RavenModal>
	);
};

export default DeleteModal;
