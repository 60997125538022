import React, { useState, useEffect } from "react";

const checkImageURL = (url?: any) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      resolve(true);
    };

    img.onerror = () => {
      resolve(false);
    };

    img.src = url;
  });
};

interface MyComponentProps {
  url?: string;
  children?: React.ReactNode;
  className?: string; // Updated to string for better type-checking
}

const CheckUrlFragment: React.FC<MyComponentProps> = ({ url, children, className }) => {
  const [imageValid, setImageValid] = useState<boolean | null>(null);

  useEffect(() => {
    if (url) {
      checkImageURL(url).then((isValid) => {
        setImageValid(isValid as boolean);
      });
    } else {
      setImageValid(null);
    }
  }, [url]);

  if (imageValid === null) {
    return null; // Return null instead of a string
  }

  return (
    <div>
      {imageValid ? (
        <figure className={`img-box ${className || ''}`}>
          <img src={url} alt="" className="img" />
        </figure>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default CheckUrlFragment;
