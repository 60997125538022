import React, { ReactNode } from 'react';
import './style/index.css';
import { icons, illustrations } from '../../../assets/icons';
import { renderToStaticMarkup } from 'react-dom/server';
import { IconVault } from '../../../utils/helper/Helper';
import { RavenButton } from '@ravenpay/raven-bank-ui';
import Loader from '../../../components/common/loader';

type ContainerProps = {
	topStyles?: any;
	activeStep?: number;
	steps?: string[];
	btnLabel?: string;
	loading?: boolean;
	loadingText?: string;
	children: ReactNode;
	className?: string;
	style?: React.CSSProperties;
	big?: boolean;
	pageMode?: boolean;
	pageTitle?: string;
	onBack?: () => void;
	topRightContent?: ReactNode;
	pageSubtitle?: string;
	isLatest?: boolean;
	btnColor?: any;
	btnDisabled?: boolean;
	onPrev?: () => void;
	onBtnClick?: () => void;
	expanded?: boolean;
	btnLoading?: boolean;
	disableBack?: boolean;
};
function ContainerLayout(props: ContainerProps) {
	return (
		<>
			{!props.pageMode && !props.isLatest && (
				<div className="container-wrap">
					{props.onBack && (
						<div className="container-page-mode__top">
							<div className="top__left-wrap">
								<figure onClick={props.onBack} className="back-icon ">
									{icons.chevron_down}
								</figure>
								<span className="title-wrap__main">
									<h5>{props.pageTitle || 'Title goes here'}</h5>
									<p>{props.pageSubtitle || 'Subtitle goes here'}</p>
								</span>
							</div>
							<div className="top-right-wrap">
								<figure className="right-wrap__cancelX">
									{props.topRightContent ? props.topRightContent : ''}
								</figure>
							</div>
						</div>
					)}

					<div className="container-page-wrap">
						<div className={`container-page `}>
							<div
								style={{
									backgroundImage: `url("${IconVault(icons.bg_lines_two)}")`,
								}}
								className="container__top"
							/>
							<div className="container__bottom"></div>
							<div className="bottom__main"></div>
							<div
								style={props.style}
								className={`content ${props.className ?? ''}`}
							>
								{props.loading ? (
									<Loader loadingText={props?.loadingText} />
								) : (
									props.children
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			{props.pageMode && (
				<>
					<div className="container-page-mode">
						<div style={props?.topStyles} className="container-page-mode__top">
							<div className="top__left-wrap">
								{!props.disableBack && (
									<figure onClick={props.onBack} className="back-icon">
										{icons.chevron_down}
									</figure>
								)}
								<span className="title-wrap__main">
									<h5>{props.pageTitle || 'Title goes here'}</h5>
									<p>{props.pageSubtitle || 'Subtitle goes here'}</p>
								</span>
							</div>
							<div className="top-right-wrap">
								{props.topRightContent ? props.topRightContent : ''}

								{/* <figure className="right-wrap__cancelX"></figure> */}
							</div>
						</div>
						<div
							style={props.style}
							className={`container-page-mode__content ${props.className}`}
						>
							{props.loading ? (
								<Loader loadingText={props?.loadingText} />
							) : (
								props.children
							)}
						</div>
					</div>
				</>
			)}

			{props.isLatest && (
				<div className="container-wrap  transfer-mode">
					{props.onBack && (
						<div className="container-page-mode__top">
							<div className="top__left-wrap">
								<figure onClick={props.onBack} className="back-icon ">
									{icons.chevron_down}
								</figure>
								<span className="title-wrap__main">
									<h5>{props.pageTitle || 'Title goes here'}</h5>
									<p>{props.pageSubtitle || 'Subtitle goes here'}</p>
								</span>
							</div>
							<div className="top-right-wrap">
								<figure className="right-wrap__cancelX">
									{props.topRightContent ? props.topRightContent : ''}
								</figure>
							</div>
						</div>
					)}

					<div className="container-page-wrap">
						<div className="container-page-header">
							{props.activeStep !== 1 ? (
								<div
									onClick={props?.onPrev}
									className="container-page-header__back-btn"
								>
									<figure>{icons.chevron_right}</figure>
									<p>Back</p>
								</div>
							) : (
								<div></div>
							)}

							<div className="container-page-header__steppers">
								{props.steps?.map(
									(
										chi:
											| string
											| number
											| boolean
											| React.ReactElement<
													any,
													string | React.JSXElementConstructor<any>
											  >
											| React.ReactFragment
											| React.ReactPortal
											| null
											| undefined,
										idx: number
									) => {
										return (
											<div
												key={idx}
												className={
													props.activeStep === idx + 1 ? 'step active' : 'step'
												}
											>
												<span
													className={
														props.activeStep === idx + 1 ? 'active' : ''
													}
												>
													<p>{idx + 1}</p>
												</span>
												<p className="stepper-title">{chi}</p>
											</div>
										);
									}
								)}
							</div>
						</div>

						<div className={`container-page `}>
							<div className="bottom__main"></div>
							<div
								style={props.style}
								className={`content ${props.className ?? ''}`}
							>
								{props.loading ? (
									<Loader loadingText={props?.loadingText} />
								) : (
									props.children
								)}
							</div>
						</div>

						<div className="container-page-footer">
							<div className="container-page-footer-btn">
								<RavenButton
									style={{ width: '100%' }}
									label={
										props?.btnLabel ||
										(props?.activeStep === 2
											? 'Complete Transaction'
											: 'Continue')
									}
									color={props.btnColor}
									onClick={props.onBtnClick}
									loading={props.btnLoading}
									disabled={props.btnDisabled}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ContainerLayout;
