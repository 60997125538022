import axios, { AxiosError, AxiosResponse } from 'axios';
import env from '../env';
import { decrypt, decrypt2, encrypt2 } from './encrypt';
import { logger } from '../services/logger';
import { getCookie, setCookie } from './helper/Helper';

const API = env.base_url;
interface DecryptedResponseData {
	data: string;
	[key: string]: any;
}

/** base url to make request to the BE end point */

const instance = axios.create({
	baseURL: API,
});

instance.interceptors.request.use(
	async (config) => {
		// const tokenTwo = localStorage?.getItem('tokunbo');
		// console.log(tokenTwo);
		// console.log(getCookie('g-rec-response'));

		const _reToken = getCookie('g-rec-response');
		if (_reToken) {
			config.headers['g-rec-response'] = _reToken;
		}
		try {
			const encryptedData = await encrypt2(config.data ?? '');

			config.data = { data: encryptedData };
		} catch (error) {
			// logger.log(error);
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response: AxiosResponse): AxiosResponse => {
		// console.log(response);

		response.data.data = decrypt2(response.data.data);
		delete response.config.transformRequest;
		delete response.data?.header;

		if (response?.data?.data === 'no authorization header parsed') {
			response.data.status = 'failure';
			delete response.data?.data;

			return response;
		}
		// console.log(response, "----two-----");

		return response;
	},
	(error: any) => {
		if (error.response?.data.status === 'fail') {
			const decryptedData = decrypt2(error.response.data.data);

			if (decryptedData === 'invalid_token') {
				// console.log("jjhk");

				// setCookie('token', '', 0);
				return;
			}

			const newError = new Error(decryptedData) as AxiosError;

			newError.response = {
				...error.response,
				data: {
					...error.response.data,
					data: decryptedData,
				} as DecryptedResponseData,
			};

			// console.log(newError.response, 'the error');

			return newError.response;
		}

		return Promise.reject(error);
	}
);

export default instance;
