import { Fragment, ReactNode, useEffect, useState } from 'react';
import './styles/index.css';
import CurrencySelector from '../../../components/common/currencySelector';

// Generate an array to store the table data

interface PageLayoutProps {
	pageTitle?: string;
	pageSubtitle?: string;
	tableTitle?: string;
	activeCurrency?: (e: any) => any;
	children?: ReactNode;
	style?: React.CSSProperties | undefined;
	topRightContent?: ReactNode;
}
const PageLayout = (props: PageLayoutProps) => {
	const [activeCurrency, setActiveCurrency] = useState('usd');

	useEffect(() => {
		props.activeCurrency ? props.activeCurrency(activeCurrency) : '';
	}, [activeCurrency]);

	// conditionally apply a class to control mobile flex

	const parentElement = document.querySelector('.history__content');

	// Check if a child element with class 'target-class' exists
	const hasTargetClass =
		parentElement && parentElement.querySelector('.no-record-mascot') !== null;

	// Apply styles to the parent based on the result
	if (hasTargetClass) {
		parentElement.classList.add('flex-one');
	} else {
		parentElement && parentElement.classList.remove('flex-one');
	}

	return (
		<Fragment>
			<div className="history-wrap">
				<div className="history-wrap__top">
					<div className="top__title ">
						<h5>{props.pageTitle ?? 'Title goes here '}</h5>
						<p>{props.pageSubtitle ?? 'Subtitle goes here'}</p>
					</div>
					{!props.topRightContent ? (
						<CurrencySelector
							defaultCurrency="NGN"
							flag
							className="currency-selector"
						/>
					) : (
						<div className="top__top-right-content">
							{props.topRightContent}
						</div>
					)}
				</div>

				<div style={props.style} className="history__content">
					{props.children}
				</div>
			</div>
		</Fragment>
	);
};

export default PageLayout;
