import overviewIcon from '../../../assets/bankbox-icon-overview.svg';
import walletIcon from '../../../assets/sidenav-icon-wallet.svg';
import transactionIcon from '../../../assets/bankbox-icon-transaction.svg';
import terminalIcon from '../../../assets/bankbox-icon-terminal.svg';
import requestIcon from '../../../assets/bankbox-icon-request.svg';
import disputeIcon from '../../../assets/bankbox-icon-disputes.svg';
import feeIcon from '../../../assets/bankbox-icon-fee.svg';
import customerIcon from '../../../assets/bankbox-icon-users.svg';
import settingIcon from '../../../assets/bankbox-icon-settings.svg';
// import user_kycIcon from '../../../assets/sidenav-icon-user-kyc.svg';
// import walletBalanceIcon from '../../../assets/wallet-icon-balance.svg';
// import walletFundingeIcon from '../../../assets/wallet-icon-history.svg';
import { icons } from '../../../assets/icons';
import { IconVault } from '../../../utils/helper/Helper';

// const overViewIcon = 

export const sideMenuList = [
	{
		name: 'Overview',
		link: '/dashboard-overview',
		icon: overviewIcon,
	},

	{
		name: 'Merchants',
		link: '/dashboard-bankbox/users',
		icon: customerIcon,
	},

	{
		name: 'Terminals',
		link: '/dashboard-bankbox/terminals',
		icon: terminalIcon,
	},

	{
		name: 'Transactions',
		link: '/dashboard-bankbox/transactions',
		icon: transactionIcon,
	},
	{
		name: 'Requests',
		link: '/dashboard-bankbox/requests',
		icon: requestIcon,
	},
	{
		name: 'Disputes',
		link: '/dashboard-bankbox/disputes',
		icon: disputeIcon,
	},
	{
		name: 'Fee Details',
		link: '/dashboard-bankbox/fee-details',
		icon: feeIcon,
	},
	{
		name: 'Settings',
		link: '/dashboard-bankbox/settings',
		icon: settingIcon,
	},
];
