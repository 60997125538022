import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import { toast } from '@ravenpay/raven-bank-ui';
import {
	AuthState,
	BusinessData,
	DirectorData,
	SettingsState,
	TransactionState,
	VerificationState,
} from './types';
import { logger } from '../services/logger';

export const updateBusinessInfo = createAsyncThunk(
	'/business-info',
	async (payload: BusinessData, thunkAPI) => {
		try {
			const data = await axios.post(`/web/update_business_info`, payload);

			// logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const uploadMerchantDocuments = createAsyncThunk(
	'/merchant-doc',
	async (payload: any, thunkAPI) => {
		try {
			const data = await axios.post(`/web/update_documents`, payload);

			// logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const AddDirectors = createAsyncThunk(
	'/settings-bvn',
	async (payload: DirectorData, thunkAPI) => {
		try {
			const data = await axios.post(`/web/add_directors_info`, payload);

			logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const initiaitBVNVerification = createAsyncThunk(
	'/initiate-bvn',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/kyc/initiate_bvn_verification`);
			logger.log(data);
			if (data?.data?.status === 'fail') {
				if (typeof data.data.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setProfile(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const initiaitIDVerification = createAsyncThunk(
	'/initiate-id',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/kyc/initiate_document_verification`);
			if (data?.data?.status === 'fail') {
				if (typeof data.data.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				// thunkAPI.dispatch(setProfile(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const validateRC = createAsyncThunk(
	'/validate-rc',
	async (
		payload: {
			rc_number: string;
		},
		thunkAPI
	) => {
		try {
			const data = await axios.post(`/web/validate_cac`, payload);
			logger.log(data?.data?.data?.message, 'valid response');

			if (data?.data?.status === 'fail') {
				if (typeof data.data.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setProfile(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
const initialState: VerificationState = {
	loading: false,
	isAuth: false,
	bvn: {},
	profile: {},
	// initialize other state properties
};

export const kycSlice = createSlice({
	name: 'auth',
	initialState,

	reducers: {
		setBvn: (state, action) => {
			state.bvn = action.payload;
			state.isAuth = true;
		},
		setProfile: (state, action) => {
			state.profile = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(initiaitBVNVerification.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(initiaitBVNVerification.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(initiaitBVNVerification.rejected, () => {
			return initialState;
		});

		builder.addCase(initiaitIDVerification.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(initiaitIDVerification.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(initiaitIDVerification.rejected, () => {
			return initialState;
		});

		builder.addCase(validateRC.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(validateRC.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(validateRC.rejected, () => {
			return initialState;
		});
	},
});

// Action creators are generated for each case reducer function
export const { setBvn, setProfile } = kycSlice.actions;

export default kycSlice.reducer;
