import React, { ReactNode } from 'react';
import { handleCopy } from '../../../utils/helper/Helper';
import { FaCheck } from 'react-icons/fa';
import { icons } from '../../../assets/icons';
import './style.css';

function Copy({
	text,
	icon,
	className,
}: {
	text: string | number;
	icon?: ReactNode;
	className?: string;
}) {
	const [copied, setCopied] = React.useState(false);
	return (
		<div  className={`checkmark__wrapper ${className} grey-bg-two`}>
			<figure onClick={() => handleCopy(text as string, setCopied)}>
				{copied ? (
					<figure className="copy-checkmark">
						<FaCheck color="#014345" />
					</figure>
				) : (
					icon || icons.copy
				)}
			</figure>
		</div>
	);
}

export default Copy;
