import React, { useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import SingleViewBankbox from './component/SingleViewBankbox';
import AllBankBox from './component/AllBankbox';
import { mapHotkey } from '../../../../utils/helper/Helper';
import ExportModal from '../../../../components/common/exportModal';

function BankboxTerminals() {
	const [showExport, setShowExport] = useState<boolean>(false);

	return (
		<>
			<DashboardLayout>
				<AllBankBox onExport={() => setShowExport(true)} />
			</DashboardLayout>
			<ExportModal
				onClose={() => setShowExport(false)}
				page="bankbox_terminals"
				visible={showExport}
			/>
		</>
	);
}

export default BankboxTerminals;
