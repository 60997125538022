import { RavenInputField, RavenModal } from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import {
	reactSelectStyle,
	removeSpace,
} from '../../../../../utils/helper/Helper';
import { bankboxAPI } from '../../../../../redux/bankbox';
import { AppDispatch } from '../../../../../redux/store';
import { ThreeDots } from 'react-loader-spinner';
import { useDarkMode } from '../../../../../hooks/useDarkMode';

interface MyComponentProps {
	onClose?: () => void;
	visible?: boolean;
	value?: any;
}

const SettleMentAccountModal: React.FC<MyComponentProps> = ({
	onClose,
	visible,
	value,
}) => {
	const { loading_fee, banks, user_merchant, user_merchant_settlement } = useSelector(
		(state: RootState) => state.bankbox
	);
	interface formComponent {
		bank: any;
		account_number: string;
		account_name: string;
	}
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const dispatch = useDispatch<AppDispatch>();

	const [details, setDetails] = useState<formComponent>({
		bank: '',
		account_number: '',
		account_name: '',
	});

	const [showError, setShowError] = useState(false);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value, account_name: '' };
		setDetails(obj);
	};
	const [loadingValidate, setLoadingValidate] = useState(false);
	const validateBankDetails = async (e: any) => {
		const payload = {
			bank_code: e || details?.bank.value,
			account_number: removeSpace(details?.account_number),
		};
		if (
			payload?.bank_code &&
			payload?.account_number?.length > 9 &&
			Object?.keys(user_merchant?.settlement_detail)?.length < 1
		) {
			setLoadingValidate(true);
			const data = await dispatch(bankboxAPI.getBankboxBankValidate(payload));
			// console.log(data);
			if (data?.payload?.status === 'success') {
				setLoadingValidate(false);
				setDetails((prev) => {
					return { ...prev, account_name: data?.payload?.data.data };
				});
			} else {
				setLoadingValidate(false);
			}
		}
		if (
			payload?.bank_code &&
			payload?.account_number?.length > 9 &&
			Object?.keys(user_merchant?.settlement_detail)?.length > 0 &&
			!checkValidateEdit()
		) {
			setLoadingValidate(true);
			const data = await dispatch(bankboxAPI.getBankboxBankValidate(payload));
			// console.log(data);
			if (data?.payload?.status === 'success') {
				setLoadingValidate(false);
				setDetails((prev) => {
					return { ...prev, account_name: data?.payload?.data.data };
				});
			} else {
				setLoadingValidate(false);
			}
		}
	};

	useEffect(() => {
		if (details?.bank && details?.account_number?.length > 9) {
			validateBankDetails(details?.bank?.value);
		}
	}, [details?.account_number || details?.bank]);

	const handleSubmit = async () => {
		// console.log(obj);
		let val;
		if (Object?.keys(user_merchant_settlement)?.length < 1) {
			const obj = {
				email: user_merchant?.user?.poseidon_email,
				account_name: details?.account_name,
				account_number: details?.account_number,
				bank_code: details?.bank?.value,
				bank_name: details?.bank?.label,
				category: 'merchant',
			};
			const data = await dispatch(bankboxAPI?.addSettleMentAccountPartner(obj));
			val = data;
      // console.log(obj);
      
		} else {
			const obj = {
				email: user_merchant?.user?.poseidon_email,
				account_name: details?.account_name,
				account_number: details?.account_number,
				bank_code: details?.bank?.value,
				bank_name: details?.bank?.label,
				category: 'merchant',
				id: String(user_merchant?.settlement_detail?.id),
			};
      // console.log(obj);
      
			const data = await dispatch(
				bankboxAPI?.editSettleMentAccountPartner(obj)
			);
			val = data;
		}
		if (val?.payload?.status === 'success') {
			dispatch(bankboxAPI.getSingleUserMerchant({ email: user_merchant?.user?.poseidon_email as string }));
			handleClose();
		}
	};

	const verifyIcon = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.01322 2.76406C5.52767 2.72301 6.01607 2.52071 6.40887 2.18597C7.32551 1.40481 8.67371 1.40481 9.59035 2.18597C9.98315 2.52071 10.4715 2.72301 10.986 2.76406C12.1865 2.85987 13.1398 3.81318 13.2356 5.01371C13.2767 5.52816 13.479 6.01655 13.8137 6.40936C14.5949 7.326 14.5949 8.67419 13.8137 9.59084C13.479 9.98364 13.2767 10.472 13.2356 10.9865C13.1398 12.187 12.1865 13.1403 10.986 13.2361C10.4715 13.2772 9.98315 13.4795 9.59035 13.8142C8.67371 14.5954 7.32551 14.5954 6.40887 13.8142C6.01607 13.4795 5.52767 13.2772 5.01322 13.2361C3.81269 13.1403 2.85938 12.187 2.76357 10.9865C2.72252 10.472 2.52022 9.98364 2.18548 9.59084C1.40432 8.67419 1.40432 7.326 2.18548 6.40936C2.52022 6.01655 2.72252 5.52816 2.76357 5.01371C2.85938 3.81318 3.81269 2.85987 5.01322 2.76406ZM10.9653 6.96578C11.2777 6.65336 11.2777 6.14683 10.9653 5.83441C10.6529 5.52199 10.1463 5.52199 9.83392 5.83441L7.19961 8.46873L6.16529 7.43441C5.85288 7.12199 5.34634 7.12199 5.03392 7.43441C4.7215 7.74683 4.7215 8.25336 5.03392 8.56578L6.63392 10.1658C6.94634 10.4782 7.45288 10.4782 7.76529 10.1658L10.9653 6.96578Z"
				fill="#EA872D"
			/>
		</svg>
	);

	const handleClose = () => {
		setDetails((prev) => {
			return {
				...prev,
				bank: '',
				account_name: '',
				account_number: '',
			};
		});
		onClose && onClose();
	};
	useEffect(() => {
		if (visible && Object?.keys(user_merchant_settlement)?.length > 0) {
			setDetails((prev) => {
				return {
					...prev,
					bank: {
						label: user_merchant?.settlement_detail?.bank,
						value: user_merchant?.settlement_detail?.bank_code,
					},
					account_name: user_merchant?.settlement_detail?.account_name,
					account_number: user_merchant?.settlement_detail?.account_number,
				};
			});
		}
	}, [visible]);

	interface Bank {
		bankName: string;
		bankCode: string;
	}

	const areBanksEqual = (bank1: Bank, bank2: Bank): boolean => {
		return (
			bank1.bankName === bank2.bankName && bank1.bankCode === bank2.bankCode
		);
	};

	const checkValidateEdit = () => {
		const value = areBanksEqual(
			{
				bankName: user_merchant?.settlement_detail?.bank,
				bankCode: user_merchant?.settlement_detail?.bank_code,
			},
			{ bankCode: details?.bank?.value, bankName: details?.bank?.label }
		);
		return value;
	};

	return (
		<RavenModal
			onClose={() => {
				handleClose();
			}}
			loading={loading_fee}
			visble={visible}
			onBtnClick={handleSubmit}
			btnColor="deep-green-light"
			btnLabel={Object?.keys(user_merchant_settlement)?.length < 1 ? `Add Settlement` : 'Edit Settlement'}
			className={`session-expired-wrap-modal`}
			disabled={
        	Object?.keys(user_merchant_settlement)?.length < 1
					? !details?.bank || !details?.account_name || !details?.account_number
					:  Object?.keys(user_merchant_settlement)?.length > 0
					? !details?.bank ||
					  !details?.account_name ||
					  !details?.account_number ||
					  checkValidateEdit()
					: false
			}
		>
			<div
				onClick={() => {
					// console.log(profile);
					// console.log(settlement_detail);
				}}
				className="assign-bb-modal"
				style={{ paddingBottom: '5rem' }}
			>
				<div className="assign-bb-modal__title">
					<h6>{`${Object?.keys(user_merchant_settlement)?.length < 1 ? 'Add' : `Edit`} Settlement Account`}</h6>
					<p>
						Enter the settlement account where all disbursment <br /> would be
						channeled to.
					</p>
				</div>
				<div
					className="assign-bb-modal__form-body"
					style={{
						marginBottom: '2rem',
						minHeight: '30rem',
						paddingBottom: 'unset',
					}}
				>
					<RavenInputField
						type="select"
						color="deep-green-light"
						value={details?.bank}
						selectStyles={reactSelectStyle}
						label="Select Bank"
						style={{ position: 'relative', zIndex: 100 }}
						placeholder="Select Bank"
						name="fee_category"
						selectOption={banks}
						onChange={(e: any) => {
							setDetails((prev) => {
								return {
									...prev,
									bank: e,
									account_name: '',
									account_number: '',
								};
							});
						}}
						// selectMenuOpen={true}
					/>
					<RavenInputField
						type={`account-number`}
						style={{ marginBottom: '-1.5rem' }}
						maxLength={10}
						color="deep-green-light"
						value={details?.account_number}
						label="Account Number"
						onChange={handleChange}
						placeholder="E.g 022...."
						name="account_number"
						labelSpanText={
							(
								<>
									{loadingValidate ? (
										<div
											style={{
												padding: '0rem',
												display: 'flex',
												justifyContent: 'flex-end',
												transform: 'translateX(1.5rem)',
											}}
											className="load-wrap"
										>
											<ThreeDots
												height="10"
												width="80"
												radius="9"
												color={theme === 'light' ? '#020202' : '#ffffff'}
												ariaLabel="three-dots-loading"
												wrapperStyle={{}}
												// wrapperClassName=""
												visible={true}
											/>
										</div>
									) : (
										'Verify'
									)}
								</>
							) as any
						}
					/>
					{details?.account_name && (
						<BadgeComponent text={details?.account_name} className="Pending">
							{verifyIcon}
						</BadgeComponent>
					)}
				</div>
			</div>
		</RavenModal>
	);
};

export default SettleMentAccountModal;
