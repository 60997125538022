import React from 'react';
import loaderImg from '../../../assets/images/raven-loader.gif';
import './style/index.css';
const Loader = (props: any) => {
	return (
		<div className="loader-wrapper">
			<figure>
				<img src={loaderImg} alt="a raven loader animation" />
			</figure>
			<p>{props.loadingText ? <p>{props?.loadingText}</p> : ''}</p>
		</div>
	);
};

export default Loader;
