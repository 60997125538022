import React, { useState } from 'react';
import './CameraAssignBox.css';
import { RavenButton } from '@ravenpay/raven-bank-ui';
import WebcamCapture from './webcam';
import Tesseract from 'tesseract.js';
import { useCamera } from './webcam/useCamera';

interface MyComponentProps {
	onCancel?: () => void;
	onFinish?: (param: any) => void;
}

const CameraAssignBox: React.FC<MyComponentProps> = ({
	onCancel,
	onFinish,
}) => {
	// const { captureImage } = useCamera();
	// const [snappedImage, setSnappedImage] = useState<string>();
	// const [step, setStep] = useState(1);
	// const [scanResult, setScanResult] = useState<Tesseract.RecognizeResult>();
	// const [scanning, setScanning] = useState(false);

	// const handleImageSnap = async () => {
	// 	const res = await captureImage();
	// 	setSnappedImage(res);
	// };

	// const redo = () => {
	// 	setStep(1);
	// 	setScanResult(undefined);
	// 	setSnappedImage(undefined);
	// };

	// function extractNumbers(inputString: string) {
	// 	// Define a regular expression to match the desired pattern
	// 	const regex = /\b(\d{20})\b/g;

	// 	// Use matchAll to find all matches of the regex in the input string
	// 	const matches = inputString.matchAll(regex);

	// 	// Initialize an array to store the extracted numbers
	// 	const numbers = [];

	// 	// Iterate over the matches and filter out the unwanted matches
	// 	for (const match of matches as any) {
	// 		// Extract the number from the match
	// 		const number = match[1];

	// 		// Check if the number matches the desired length and contains only digits
	// 		if (number.length === 20 && /^\d+$/.test(number)) {
	// 			numbers.push(number);
	// 		}
	// 	}

	// 	// Return the array of numbers
	// 	return numbers;
	// }

	// const scanImage = async () => {
	// 	setScanning(true);

	// 	const worker = await Tesseract.createWorker('eng');

	// 	const res = await worker.recognize(snappedImage as any);
	// 	console.log(res.data.text);
	// 	console.log(extractNumbers(String(res.data.text)), '-----extract 1 ------');

	// 	await worker.terminate();
	// 	setScanning(false);
	// 	setScanResult(res);
	// };



	return (
		<div className="camera-assign-box-wrap">
			{/* <p className="text"> */}
			{/* Please take <span>1 clear back view picture</span> of your bankbox
				device to collect details, kindly place the <span>GREY PART</span>{' '}
				of the device to fit into the camera space for proper scanning */}
			{/* <span>NOTE:</span>
				Please ensure to capture a clear image of the
				<span style={{ textTransform: 'capitalize' }}>serial number</span>
				located below the barcode lines at back of your BankBox device.
			</p> */}
			{/* <p className="text">
				<span>Note:</span>
        <br />
				kindly place the bar grey part of the device to fit into the camera space for proper
				scanning
			</p> */}
			<WebcamCapture
				onFinish={(param) => {
					onFinish && onFinish(param);
				}}
				onCancel={onCancel}
			/>
		
		</div>
	);
};

export default CameraAssignBox;
