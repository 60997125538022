import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenToolTip,
} from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import './style/DisputeDetailModal.css';
import BadgeComponent from '../../../../components/common/badge/BadgeComponent';
import {
	capitalizeFirstLetter,
	checkDisputeStatus,
	checkDisputeStatusColor,
	formatDateTime,
	formatNumWithComma,
	formatTypeFunction,
	lowerCaseWrap,
	reactSelectStyle,
	returnStatus,
	symbol,
	trimLongString,
} from '../../../../utils/helper/Helper';
import Copy from '../../../../components/common/copyCheck';
import { bankboxAPI } from '../../../../redux/bankbox';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../redux/types';

interface MyComponentProps {
	visible?: boolean;
	onCancel?: () => void;
	onFinish?: () => void;
	onStatus?: () => void;
	detail?: any;
}

const DisputeDetailModal: React.FC<MyComponentProps> = ({
	visible,
	onCancel,
	detail,
	onFinish,
	onStatus,
}) => {
	const headList = ['Dispute Details', 'Transaction', 'Change Status'];
	const [activeContent, setActiveContent] = useState('Dispute Details');
	const { loading } = useSelector((state: RootState) => state.bankbox);
	const dispatch = useDispatch();

	const copyIcon = (
		<svg
			className="img"
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.375 1.75H8.51667C9.82346 1.75 10.4768 1.75 10.976 2.00432C11.415 2.22802 11.772 2.58498 11.9957 3.02402C12.25 3.52315 12.25 4.17654 12.25 5.48333V9.625M3.61667 12.25H8.34167C8.99506 12.25 9.32176 12.25 9.57132 12.1228C9.79084 12.011 9.96932 11.8325 10.0812 11.613C10.2083 11.3634 10.2083 11.0367 10.2083 10.3833V5.65833C10.2083 5.00494 10.2083 4.67824 10.0812 4.42868C9.96932 4.20916 9.79084 4.03068 9.57132 3.91883C9.32176 3.79167 8.99506 3.79167 8.34167 3.79167H3.61667C2.96327 3.79167 2.63657 3.79167 2.38701 3.91883C2.16749 4.03068 1.98901 4.20916 1.87716 4.42868C1.75 4.67824 1.75 5.00494 1.75 5.65833V10.3833C1.75 11.0367 1.75 11.3634 1.87716 11.613C1.98901 11.8325 2.16749 12.011 2.38701 12.1228C2.63657 12.25 2.96327 12.25 3.61667 12.25Z"
				stroke="#014345"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const infoIcon = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_291_8854)">
				<path
					d="M6.06016 5.99967C6.2169 5.55412 6.52626 5.17841 6.93347 4.9391C7.34067 4.69978 7.81943 4.6123 8.28495 4.69215C8.75047 4.772 9.17271 5.01402 9.47688 5.37536C9.78106 5.7367 9.94753 6.19402 9.94683 6.66634C9.94683 7.99967 7.94683 8.66634 7.94683 8.66634M8.00016 11.333H8.00683M14.6668 7.99967C14.6668 11.6816 11.6821 14.6663 8.00016 14.6663C4.31826 14.6663 1.3335 11.6816 1.3335 7.99967C1.3335 4.31778 4.31826 1.33301 8.00016 1.33301C11.6821 1.33301 14.6668 4.31778 14.6668 7.99967Z"
					stroke="#EA872D"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_291_8854">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);

	interface formComponent {
		title: string;
		message: string;
		status: any;
	}
	const [details, setDetails] = useState<formComponent>({
		title: '',
		message: '',
		status: '',
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const [sendLoading, setSendLoading] = useState(false);
	const handleSubmitResponse = async () => {
		const obj = {
			email: detail?.email,
			// action: 'review', //discard, resolve, sendCustomEmail
			message: details?.message,
			title: details?.title,
		};

		setSendLoading(true);
		const data = await dispatch(bankboxAPI.sendCustomEmail(obj as any));
		// console.log(data);
		if (data?.payload?.status === 'success') {
			setSendLoading(false);
			setActiveContent('Dispute Details');
			onCancel && onCancel();
			setDetails((prev) => {
				return { ...prev, message: '', title: '' };
			});
		} else {
			setSendLoading(false);
		}
	};

	const selectOptions = [
		{ label: 'Reviewed', value: 'review' },
		{ label: 'Resolved', value: 'resolve' },
		{ label: 'Discarded', value: 'discard' },
	];

	const handleSubmitStatusChange = async () => {
		const obj = {
			email: detail?.email,
			action: details?.status?.value,
			reason: details?.message,
			id: String(detail?.id),
		};

		// console.log(obj);
		// return;

		setSendLoading(true);
		const data = await dispatch(bankboxAPI.changeDisputeStatus(obj as any));
		// console.log(data);
		if (data?.payload?.status === 'success') {
			setSendLoading(false);
			setActiveContent('Dispute Details');
			// onCancel && onCancel();
			onFinish && onFinish();
			setDetails((prev) => {
				return { ...prev, message: '', title: '' };
			});
		} else {
			setSendLoading(false);
		}
	};

	useEffect(() => {
		setDetails((prev) => {
			return {
				...prev,
				status: {
					label: lowerCaseWrap(checkDisputeStatus(detail?.status)),
					value: checkDisputeStatus(detail?.status),
				},
			};
		});
	}, [visible]);

	const formatDiscarded = (param: any) => {
		let val;
		if (param === 'discarded') {
			val === 'discard';
		} else {
			val = param;
		}
		console.log(val);

		return val;
	};

	const filterOutOption = (param: any) => {
		console.log(param);

		if (param) {
			const newList = selectOptions?.filter((chi) => chi?.value !== param);

			return newList;
		}
	};

	return (
		<RavenModal
			className={`dispute-detail-modal-classname`}
			onBtnClick={() => {}}
			onClose={() => {
				setActiveContent('Dispute Details');
				onCancel && onCancel();
			}}
			visble={visible}
		>
			{/* dispute modal content start */}
			<div
				onClick={() => {
					// console.log(detail);
				}}
				className="dispute-modal-content-wrap-index"
			>
				{/* head select start */}
				<div className="title-select-wrap grey-bg">
					{headList?.map((chi, idx) => {
						return (
							<div
								key={idx}
								className={`title-select  ${
									activeContent === chi && 'title-select-active white-black-bg'
								}`}
								onClick={() => {
									activeContent !== chi && setActiveContent(chi);
								}}
							>
								<span>{chi}</span>
							</div>
						);
					})}
				</div>
				{/* head select end */}
				{/* content to show syart */}
				<div className="content-to-show-wrap">
					{/* active cpontent is details ------------ */}
					{activeContent === 'Dispute Details' && (
						<>
							<div className="value-label-wrap-index-box border-theme">
								{/* value label start */}
								<div className="label-value-box border-theme-bottom">
									<p className="label">Merchant</p>
									<p className="value">{detail?.email || '---'}</p>
								</div>
								{/* value label end */}
								{/* value label start */}
								<div className="label-value-box">
									<p className="label">Refrence</p>
									<div className="value copy-wrap-index ">
										{detail?.transactions?.length > 0
											? trimLongString(detail?.transactions[0]?.reference, 22)
											: '---'}
										{detail?.transactions?.length > 0 && (
											<figure className="img-box">
												{
													<Copy
														text={
															detail?.transactions?.length > 0
																? detail?.transactions[0]?.reference
																: ''
														}
													/>
												}
											</figure>
										)}
									</div>
								</div>
								{/* value label end */}
								{/* value label start */}
								<div className="label-value-box border-theme-bottom">
									<p className="label">Note</p>
									<p style={{ textAlign: 'left' }} className="value">
										{/* {capitalizeFirstLetter(trimLongString(detail?.note, 12)) ||
											'---'} */}
										{detail?.note || '---'}
									</p>
								</div>
								{/* value label end */}
								{/* value label start */}
								<div className="label-value-box border-theme-bottom">
									<p className="label">Type</p>
									<p className="value">
										{capitalizeFirstLetter(detail?.type) || '---'}
									</p>
								</div>
								{/* value label end */}
								{/* value label start */}
								<div className="label-value-box border-theme-bottom">
									<p className="label">Dispute Status</p>
									<div className="value">
										<BadgeComponent
											className={checkDisputeStatusColor(detail?.status)}
											text={
												checkDisputeStatus(detail?.status) === 'discard'
													? 'discarded'
													: checkDisputeStatus(detail?.status)
											}
										/>
									</div>
								</div>
								{/* value label end */}
								{/* value label start */}
								<div className="label-value-box ">
									<p className="label">Date Logged</p>
									<p className="value">
										{formatDateTime(detail?.date_of_dispute)}
									</p>
								</div>
								{/* value label end */}
							</div>
							<div className="btn-box-wrap">
								{' '}
								{String(detail?.status) !== '4' && (
									<RavenButton
										style={{ width: '100%' }}
										color={`deep-green-light`}
										// label={
										// 	String(detail?.status) === '0'
										// 		? 'Set to In Review'
										// 		: String(detail?.status) === '3'
										// 		? 'Set to Resolved'
										// 		: ''
										// }
										label="Change status"
										loading={sendLoading}
										onClick={() => {
											setActiveContent('Change Status');
											// onStatus && onStatus()
										}}
									/>
								)}
							</div>
						</>
					)}
					{/* active cpontent is details end ------------ */}
					{/* active cpontent is details ------------ */}
					{activeContent === 'Transaction' && (
						<>
							{detail?.transactions?.length > 0 ? (
								<div className="value-label-wrap-index-box border-theme">
									{/* value label start */}
									<div className="label-value-box border-theme-bottom">
										<p className="label">Amount</p>
										<p className="value">
											{symbol('ngn') +
												formatNumWithComma(
													detail?.transactions[0]?.amount,
													'ngn'
												)}
										</p>
									</div>
									{/* value label end */}
									{/* value label start */}
									<div className="label-value-box border-theme-bottom">
										<p className="label">Type</p>
										<p className="value">
											{capitalizeFirstLetter(
												formatTypeFunction(detail?.transactions[0]?.type)
											) || '---'}
										</p>
									</div>
									{/* value label end */} {/* value label start */}
									<div className="label-value-box border-theme-bottom">
										<p className="label">Channel</p>
										<p className="value">
											{capitalizeFirstLetter(detail?.transactions[0]?.channel)}
										</p>
									</div>
									{/* value label end */} {/* value label start */}
									{/* <div className="label-value-box border-theme-bottom">
										<p className="label">Cashier</p>
										<p className="value">
											{detail?.transactions[0]?.cashier || '---'}
										</p>
									</div> */}
									{/* value label end */}
									{/* value label start */}
									<div className="label-value-box border-theme-bottom">
										<p className="label">TID</p>
										<div className="value copy-wrap-index">
											{detail?.transactions[0]?.t_id || '---'}
											{detail?.transactions[0]?.t_id && (
												<div className="copy-box ">
													<figure className="img-box">
														{<Copy text={detail?.transactions[0]?.t_id} />}
													</figure>
												</div>
											)}
										</div>
									</div>
									{/* value label end */}
									{/* value label start */}
									<div className="label-value-box border-theme-bottom">
										<p className="label">Serial No.</p>
										<div className="value copy-wrap-index">
											{detail?.transactions[0]?.serial || '---'}
											{detail?.transactions[0]?.serial && (
												<div className="copy-box ">
													<figure className="img-box">
														{<Copy text={detail?.transactions[0]?.serial} />}
													</figure>
												</div>
											)}
										</div>
									</div>
									{/* value label end */}
									{/* value label start */}
									<div className="label-value-box border-theme-bottom">
										<p className="label">Stan</p>
										<div className="value copy-wrap-index">
											{detail?.transactions[0]?.stan || '---'}
											{detail?.transactions[0]?.stan && (
												<div className="copy-box ">
													<figure className="img-box">
														{<Copy text={detail?.transactions[0]?.stan} />}
													</figure>
												</div>
											)}
										</div>
									</div>
									{/* value label end */}
									{/* value label start */}
									<div className="label-value-box border-theme-bottom">
										<p className="label">RRN</p>
										<div className="value copy-wrap-index">
											{detail?.transactions[0]?.rrn || '---'}
											{/* <div className="copy-box grey-bg"> */}
											{detail?.transactions[0]?.rrn && (
												<figure className="img-box">
													{<Copy text={detail?.transactions[0]?.rrn} />}
												</figure>
											)}

											{/* </div> */}
										</div>
									</div>
									{/* value label end */}
									{/* value label start */}
									<div className="label-value-box border-theme-bottom">
										<p className="label">Fee</p>
										<p className="value">
											{symbol('ngn') +
												formatNumWithComma(detail?.transactions[0]?.fee, 'ngn')}
										</p>
									</div>
									{/* value label end */}
									{/* value label start */}
									<div className="label-value-box border-theme-bottom">
										<p className="label">PNL</p>
										<p className="value">
											{symbol('ngn') +
												formatNumWithComma(detail?.transactions[0]?.pnl, 'ngn')}
										</p>
									</div>
									{/* value label end */}
									{/* value label start */}
									<div className="label-value-box border-theme-bottom">
										<p className="label"> Status</p>
										<div className="value">
											<BadgeComponent
												className={returnStatus(
													detail?.transactions[0]?.dispute_status
												)}
												text={trimLongString(
													String(
														returnStatus(
															detail?.transactions[0]?.dispute_status
														)
													),
													7
												)}
											/>
										</div>
									</div>
									{/* value label end */}
									{/* value label start */}
									<div className="label-value-box">
										<p className="label">Date Logged</p>
										<p className="value">
											{formatDateTime(detail?.created_at) || '---'}
										</p>
									</div>
									{/* value label end */}
								</div>
							) : (
								<>
									<div className="empty_all_bb">
										<h6>No Transaction Found</h6>
										<p>
											It appears that no transaction has been made by this user
											yet.
										</p>
									</div>
								</>
							)}
						</>
					)}
					{/* active cpontent is details end ------------ */}
					{/* active cpontent is details ------------ */}
					{activeContent === 'Change Status' && (
						<>
							<div className="form-content-wrap-index">
								{/* badge info start */}
								<div
									style={{ position: 'relative', zIndex: 101 }}
									className="badge-info-box"
								>
									<div className="bagde-box grey-bg">
										<span>{detail?.email || '---'}</span>
									</div>
									<div className="info-box">
										<figure className="img-box tooltip-hover-wrap">
											{infoIcon}
											<RavenToolTip
												textColor="white-light"
												color={`deep-green-light`}
												position={`bottom-center`}
												text="This is the email that would receive the response through email."
											/>
										</figure>
									</div>
								</div>
								{/* badge info end */}
								{/* form group start */}
								<RavenInputField
									placeholder=" "
									label="Status"
									type="select"
									color="deep-green-light"
									value={details?.status}
									name={`status`}
									onChange={(e: any) => {
										setDetails((prev) => {
											return { ...prev, status: e };
										});
									}}
									selectOption={filterOutOption(
										checkDisputeStatus(detail?.status)
									)}
									style={{ position: 'relative', zIndex: 100 }}
									selectStyles={reactSelectStyle}
								/>
								{/* form group end */}
								{/* form group start */}
								<RavenInputField
									label={`Response Message * `}
									type="textarea"
									color="deep-green-light"
									placeholder="Write your thoughts here... "
									value={details?.message}
									name={`message`}
									onChange={handleChange}
								/>
								{/* form group end */}

								<div className="btn-box-wrap">
									{' '}
									<RavenButton
										style={{ width: '100%' }}
										color={`deep-green-light`}
										label="Send a Response"
										disabled={!details?.message || !details?.status}
										// onClick={handleSubmitResponse}
										onClick={handleSubmitStatusChange}
										loading={sendLoading}
									/>
								</div>
							</div>
						</>
					)}
					{/* active cpontent is details end ------------ */}
				</div>
				{/* content to show end */}
			</div>
			{/* dispute modal content end */}
		</RavenModal>
	);
};

export default DisputeDetailModal;
