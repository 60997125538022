import { ONSWATCH, TOGGLE_MENU, TOGGLE_THEME } from './actionTypes';

export const toggleTheme = (payload: 'light' | 'dark') => ({
	type: TOGGLE_THEME,
	payload,
});

export const toggleMenu = (payload?: boolean) => ({
	type: TOGGLE_MENU,
	payload,
});

export const setBrandColor = (payload?: string) => ({
	type: ONSWATCH,
	payload,
});
