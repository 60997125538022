import { RavenButton, RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import "./DeleetModal.css";

interface MyComponentProps {
    visible?: boolean;
    text?: string;
    title?: string;
    onCancel?: () => void;
    onDelete?: () => void;
    loading?: boolean ;
    deleteText?: string;
    buttonColor?: string;
    secondText?: string;
}

const DeleteModal: React.FC<MyComponentProps> = ({
  visible,
  text,
  title,
  onCancel,
  onDelete,
  loading,
  deleteText,
  buttonColor,
  secondText,
}) => {
  return (
    <RavenModal
      className={`delete-modal-reuse-wrap`}
      visble={visible}
      effect={`fadeInRight`}
      onBtnClick={() => {}}
      // outerClose
    >
      <div className="container-wrap">
        <p className="title">{title || "Delete Link"}</p>
        <p className="text grey-white-color">
          {text ||
            "Remove this beneficiaries, from your beneficiaries list, this mean you won’t be able to perform transaction, "}
        </p>
      </div>
      <div className="two-button-wrap">
        <p onClick={onCancel} className="cancel">
          {secondText || "Cancel"}
        </p>
        <RavenButton
          color={buttonColor || `error-light` as any}
          label={deleteText || "Delete"}
          loading={loading}
          size={`small`}
          onClick={onDelete}
        />
      </div>
    </RavenModal>
  );
};

export default DeleteModal;
