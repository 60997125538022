import React from 'react';
import './style/index.css';
import { icons } from '../../../assets/icons';
import Skeleton from 'react-loading-skeleton';

interface PaginationProps {
	currentPage: number;
	itemsPerPage: number;
	totalItems: number;
	className?: string;
	onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
	currentPage,
	itemsPerPage,
	totalItems,
	onPageChange,
	className,
}) => {
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	const handlePageChange = (page: number) => {
		if (page >= 1 && page <= totalPages) {
			onPageChange(page);
		}
	};

	const renderPages = () => {
		const pages = [];

		for (let i = 1; i <= totalPages; i++) {
			const isActive = i === currentPage;

			pages.push(
				<div
					key={i}
					className={`pagination__page${
						isActive ? ' pagination__page--active' : ''
					}`}
					onClick={() => handlePageChange(i)}
				>
					{i}
				</div>
			);
		}

		return pages;
	};

	return (
		<div className={`pagination ${className}`}>
			<div className="pagination__controls">
				{typeof currentPage !== 'number' && itemsPerPage ? (
					<></>
				) : (
					<div className="pagination__page-count">
						{isNaN(totalItems) ? (
							<Skeleton width={80} height={20} />
						) : (
							`${((currentPage ?? 0) - 1) * itemsPerPage + 1} - ${Math.min(
								currentPage * itemsPerPage,
								totalItems ?? 0
							)} of ${totalItems ?? 0}`
						)}
					</div>
				)}
				<div
					className={`pagination__button${
						currentPage === 1 ? ' pagination__button--disabled' : ''
					}`}
					onClick={() => handlePageChange(currentPage - 1)}
				>
					{icons.chevron_left}
				</div>
				<div
					className={`pagination__button${
						currentPage === totalPages ? ' pagination__button--disabled' : ''
					}`}
					onClick={() => handlePageChange(currentPage + 1)}
				>
					{icons.chevron_right}
				</div>
			</div>
		</div>
	);
};

export default Pagination;
