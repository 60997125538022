import React, { useEffect, useState } from 'react';
import './style.css';
import { icons } from '../../../assets/icons';
import { logger } from '../../../services/logger';

export const currencies = [
	{
		country: 'NG',
		iso: 'NGN',
		symbol: '₦',
		label: 'Naira',
		disabled: false,
	},
	{
		country: 'UK',
		iso: 'GPB',
		symbol: '£',
		label: 'British Pounds',
		disabled: true,
	},
	{
		country: 'US',
		iso: 'USD',
		symbol: '$',
		label: 'US Dollar',
		disabled: true,
	},
	{
		country: 'EU',
		iso: 'EUR',
		label: 'Euro',
		symbol: '€',
		disabled: true,
	},

	{
		country: 'RW',
		iso: 'RWF',
		symbol: 'R₣',
		label: 'Rwandan Franc',
		disabled: true,
	},
	{
		country: 'CA',
		iso: 'CAD',
		symbol: 'CA$',
		label: 'Canadian Dollar',
		disabled: true,
	},
];

type CurrencyProps = {
	defaultCurrency?: string;
	onCurrencyChange?: (e?: any) => any;
	border?: string;
	className?: string;
	flag?: boolean;
	labelClassName?: string;
	onChange?: (e?: any) => any;
	dropdownClassName?: string;
};
function CurrencySelector(props: CurrencyProps) {
	const { defaultCurrency } = props;

	let defCurr =
		defaultCurrency?.toUpperCase() === 'GPB'
			? currencies[1]
			: defaultCurrency?.toUpperCase() === 'USD'
			? currencies[2]
			: defaultCurrency?.toUpperCase() === 'EUR'
			? currencies[3]
			: defaultCurrency?.toUpperCase() === 'NGN'
			? currencies[0]
			: defaultCurrency?.toUpperCase() === 'RW'
			? currencies[4]
			: defaultCurrency?.toUpperCase() === 'CAD'
			? currencies[5]
			: currencies[5];

	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState({
		...defCurr,
	});

	useEffect(() => {
		// setSelected(props.onCurrencyChange);
		// props.onChange(props.onCurrencyChange);
	}, [props.onCurrencyChange]);

	logger.log(defaultCurrency, 'default currency');
	return (
		<div
			style={{ border: props.border ? '' : 'none' }}
			className={`currency ${props.className}`}
		>
			{props.flag && (
				<div className="currency__flag">
					<figure>
						{
							icons[
								selected?.country?.toLowerCase() ??
									defCurr?.country?.toLowerCase()
							]
						}
					</figure>
				</div>
			)}

			<div onClick={() => setIsOpen(!isOpen)} className="currency__value">
				<span className={props.labelClassName}>
					{selected?.iso ?? defCurr?.iso}
				</span>
				<figure onClick={() => setIsOpen(!isOpen)}>{icons.chevron_down}</figure>
			</div>
			<div
				className={`currency__dropdown ${props.dropdownClassName} ${
					isOpen && 'open'
				}`}
			>
				{currencies.map((chi, idx) => {
					return (
						<div
							onClick={() => {
								if (!chi.disabled) {
									setSelected(chi);
									props.onChange ? props.onChange(chi) : '';
								}
								setIsOpen(false);
							}}
							key={idx}
							style={{ cursor: chi?.disabled ? 'no-drop' : 'cursor' }}
							className={`dropdown__select-item ${
								chi === selected && 'active'
							}`}
						>
							<figure>{icons[chi?.country?.toLowerCase()]}</figure>
							<span>{chi.iso}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default CurrencySelector;
