import { RavenButton } from '@ravenpay/raven-bank-ui';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import RootState from '../../../../../redux/types';
import Loader from '../../../../../components/common/loader';
import ContainerLayout from '../../../../../layout/dashboard/container/index';

function EmptyBankBoxOverview({ onClick }: any) {
	const dispatch = useDispatch();
	const { loading, profile } = useSelector(
		(state: RootState) => state.settings
	);

	const [delay, setDelay] = useState(false);
	// React.useEffect(() => {
	// 	if(profile?.merchantInfo?.bankbox_key){
	// 			dispatch(bankboxAPI.getDashboardSummary({}));
	// 	}

	// }, [profile]);

	React.useEffect(() => {
		if (loading) {
			setDelay(false);
		} else {
			setDelay(true);
			setTimeout(() => {
				setDelay(false);
			}, 4000);
		}
	}, []);

	

	return (
		<>
			{loading || delay ? (
				<ContainerLayout
					pageTitle="BankBox"
					topRightContent={
						<>
					
						</>
					}
					pageMode
					style={{
						height: '100%',
						backgroundColor: loading || delay ? 'white' : 'unset',
						borderRadius: loading || delay ? '1rem' : 'unset',
					}}
					disableBack
					loading={loading || delay}
					pageSubtitle="Manage BankBox devices on the platform"
				>
					{' '}
				</ContainerLayout>
			) : (
				<div className="bankbox-overview">
					<div className="bankbox-overview__left"></div>

					<div className="bankbox-overview__right">
						<div className="bankbox-overview__right--title">
							<h6>BankBox: The pocket friendly payment terminal.</h6>

							{/* <p>Your Business, Your Brand, Your Payment Solution.</p> */}
							<p>
								Seamlessly manage offline in-person payments for your business,
								with the BankBox.
							</p>
						</div>

						<div className="bankbox-overview__right--points">
							<div className="main">
								<div className="bullet">
									<p></p>
								</div>
								<p className="point">
									BankBox offers card and NFC payments for a seamless and
									adaptable customer experience
								</p>
							</div>
							<div className="main">
								<div className="bullet">
									<p></p>
								</div>
								<p className="point">
									Customize BankBox with your brand identity for a professional,
									consistent payment solution.
								</p>
							</div>
						</div>

						<div className="bankbox-overview__right--activate-btn">
							<RavenButton
								onClick={onClick}
								label="Setup Profile"
								color="deep-green-light"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default EmptyBankBoxOverview;
