const category = [
	'Agriculture',
	'Aviation',
	'Commercial/ Retail',
	'Construction',
	'Education and Training',
	'Energy and Power Generation',
	'FMCG',
	'Fashion',
	'Financial Services',
	'Haulage/ Logistics',
	'Healthcare',
	'ICT',
	'Manufacturing',
	'Media & Entertainment',
	'Oil & Gas',
	'Professional Services',
	'Telecommunication',
	'Tourism/ Hospitality',
	'Transportation',
	'Waste Management',
	'Others',
];

export const businessCategories = category.map((item) => ({
	label: item,
	value: item.toLowerCase().replace(/[^a-zA-Z]/g, ''),
}));
