import { RavenButton } from '@ravenpay/raven-bank-ui';
import React from 'react';
import './styles.css';
const ReuseVerified = ({
	pending,
	text,
	failed,
	onFailClick,
	btnLabel,
}: any) => {
	return (
		<div className="reusable-verified-pending-wrap grey-bg">
			<div className="img-wrap">
				<figure className="img-box">
					{pending ? (
						<svg
							width="50"
							height="50"
							viewBox="0 0 50 50"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="img"
						>
							<path
								d="M19.7582 3.47427L0.916592 39.7897C-1.41309 44.2801 1.49919 49.8939 6.15856 49.8939H43.8417C48.5011 49.8939 51.4141 44.2809 49.0837 39.7897L30.2421 3.47427C27.9124 -1.01616 22.0879 -1.01616 19.7575 3.47427H19.7582Z"
								fill="#EA872D"
							/>
							<path
								d="M43.8416 50H6.15841C3.93528 50 1.94463 48.7211 0.833414 46.579C-0.277805 44.437 -0.277805 41.8784 0.833414 39.7364L19.675 3.42096C20.7862 1.27891 22.7769 0 25 0C27.2231 0 29.2138 1.27891 30.325 3.42096L49.1666 39.7364C50.2778 41.8784 50.2778 44.4362 49.1666 46.579C48.0554 48.7211 46.0647 50 43.8416 50ZM25 0.21251C22.8461 0.21251 20.9177 1.45138 19.8411 3.52721L0.999474 39.8426C-0.0771488 41.9177 -0.0771488 44.3962 0.999474 46.472C2.0761 48.5471 4.00516 49.7867 6.15841 49.7867H43.8416C45.9955 49.7867 47.9239 48.5478 49.0012 46.472C50.0778 44.3962 50.0778 41.9184 49.0012 39.8426L30.1596 3.52721C29.083 1.45138 27.1539 0.21251 25.0007 0.21251H25Z"
								fill="url(#paint0_linear_12277_60883)"
							/>
							<path
								d="M28.2508 15.6288C28.0052 20.3341 27.7547 25.1194 27.5056 29.9055C27.471 30.5654 27.4973 31.2353 27.3991 31.8828C27.204 33.171 26.2131 34.0603 25.059 34.0718C23.8676 34.0834 22.8318 33.2033 22.6969 31.8582C22.5107 30.001 22.4533 28.1269 22.3488 26.2597C22.1489 22.6833 21.9724 19.1045 21.751 15.5287C21.5884 12.9016 23.2822 10.8658 25.4493 11.153C26.9431 11.3509 28.1242 12.7076 28.246 14.3861C28.2743 14.7711 28.2501 15.1615 28.2501 15.6296L28.2508 15.6288Z"
								fill="#FFF6ED"
							/>
							<path
								d="M21.8046 40.1013C21.824 38.1533 23.2881 36.5464 25.0379 36.5518C26.8272 36.5572 28.2131 38.1702 28.1978 40.2291C28.1833 42.164 26.749 43.7456 25.0144 43.7386C23.232 43.7309 21.7839 42.0901 21.8039 40.1013H21.8046Z"
								fill="#FFF6ED"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_12277_60883"
									x1="-0.000345959"
									y1="24.9992"
									x2="50.0003"
									y2="24.9992"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#C4FFFF" />
									<stop offset="1" stopColor="#B3D6D6" />
								</linearGradient>
							</defs>
						</svg>
					) : failed ? (
						<svg
							className="img"
							width="58"
							height="57"
							viewBox="0 0 58 57"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14.9607 14.2449C22.7143 6.49128 35.2857 6.49128 43.0395 14.2451C50.7931 21.9988 50.7931 34.5701 43.0395 42.3237C35.2858 50.0774 22.7143 50.0776 14.9607 42.3239C7.20703 34.5703 7.20686 21.9988 14.9607 14.2449Z"
								fill="#FF0F00"
							/>
							<path
								d="M14.8578 42.4263C7.05999 34.6284 7.05999 21.9401 14.8578 14.1423C22.6558 6.34426 35.3442 6.34426 43.142 14.1421C50.94 21.9401 50.94 34.6284 43.142 42.4264C35.344 50.2245 22.6558 50.2243 14.8578 42.4263ZM42.9366 14.3475C35.2519 6.66273 22.7479 6.6629 15.0634 14.3475C7.37863 22.0322 7.37863 34.536 15.0634 42.2207C22.7481 49.9055 35.2521 49.9053 42.9366 42.2207C50.6214 34.536 50.6212 22.032 42.9366 14.3475Z"
								fill="url(#paint0_linear_15126_1263)"
							/>
							<path
								d="M19.5176 36.2935C19.5176 35.9165 19.6616 35.5395 19.9492 35.2519L35.9671 19.2337C36.5424 18.6584 37.4752 18.6584 38.0505 19.2337C38.6259 19.8091 38.6259 20.7418 38.0505 21.3172L22.0325 37.3352C21.4571 37.9106 20.5244 37.9106 19.9492 37.3354C19.6616 37.0478 19.5176 36.6705 19.5178 36.2937L19.5176 36.2935Z"
								fill="white"
							/>
							<path
								d="M19.5181 20.2755C19.5179 19.8983 19.6619 19.5213 19.9495 19.2337C20.5248 18.6584 21.4576 18.6584 22.0329 19.2337L38.051 35.2518C38.6262 35.8273 38.6262 36.76 38.051 37.3352C37.4756 37.9106 36.5429 37.9106 35.9675 37.3352L19.9497 21.317C19.6621 21.0294 19.5181 20.6524 19.5181 20.2755Z"
								fill="white"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_15126_1263"
									x1="43.1422"
									y1="42.4263"
									x2="14.858"
									y2="14.1421"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#C4FFFF" />
									<stop offset="1" stopColor="#D5FFFF" />
								</linearGradient>
							</defs>
						</svg>
					) : (
						<svg
							width="45"
							height="45"
							viewBox="0 0 45 45"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="img"
						>
							<path
								d="M34.5479 8.20545C34.9683 9.22225 35.7753 10.0305 36.7915 10.4524L40.3545 11.9284C41.3714 12.3496 42.1793 13.1575 42.6005 14.1744C43.0216 15.1912 43.0216 16.3338 42.6005 17.3507L41.1256 20.9113C40.7043 21.9287 40.7037 23.0724 41.127 24.0892L42.5992 27.6488C42.808 28.1525 42.9155 28.6923 42.9156 29.2376C42.9157 29.7828 42.8084 30.3227 42.5997 30.8264C42.3911 31.3301 42.0853 31.7878 41.6997 32.1732C41.3141 32.5587 40.8563 32.8644 40.3526 33.0729L36.7921 34.5478C35.7753 34.9682 34.9671 35.7752 34.5452 36.7914L33.0693 40.3546C32.6481 41.3715 31.8402 42.1794 30.8234 42.6006C29.8065 43.0218 28.664 43.0218 27.6472 42.6006L24.0866 41.1257C23.0698 40.7055 21.9277 40.7064 20.9114 41.1281L17.3483 42.6019C16.3321 43.0221 15.1905 43.0218 14.1745 42.6009C13.1585 42.1801 12.3511 41.3731 11.9296 40.3573L10.4533 36.793C10.0329 35.7762 9.22587 34.968 8.20974 34.5461L4.64666 33.0701C3.63025 32.6491 2.82261 31.8417 2.40127 30.8254C1.97992 29.8091 1.97935 28.6671 2.39968 27.6504L3.8745 24.0897C4.29465 23.0728 4.29379 21.9306 3.87211 20.9144L2.39941 17.3486C2.19061 16.8449 2.0831 16.3051 2.08301 15.7599C2.08292 15.2147 2.19026 14.6748 2.3989 14.171C2.60754 13.6673 2.91338 13.2097 3.29896 12.8242C3.68453 12.4387 4.14228 12.133 4.64605 11.9245L8.20657 10.4497C9.22245 10.0296 10.0302 9.22361 10.4524 8.20861L11.9283 4.6454C12.3495 3.62852 13.1574 2.82061 14.1742 2.3994C15.1911 1.97819 16.3336 1.97819 17.3504 2.3994L20.9109 3.87427C21.9278 4.29444 23.0699 4.29358 24.0862 3.87189L27.6507 2.40169C28.6674 1.98072 29.8097 1.9808 30.8264 2.40193C31.843 2.82305 32.6508 3.63073 33.072 4.64735L34.5484 8.21162L34.5479 8.20545Z"
								fill="#1ACE37"
							/>
							<path
								d="M16.3747 22.5002L20.458 26.5835L29.6455 17.396M34.5479 8.20545C34.9683 9.22225 35.7753 10.0305 36.7915 10.4524L40.3545 11.9284C41.3714 12.3496 42.1793 13.1575 42.6005 14.1744C43.0216 15.1912 43.0216 16.3338 42.6005 17.3507L41.1256 20.9113C40.7043 21.9287 40.7037 23.0724 41.127 24.0892L42.5992 27.6488C42.808 28.1525 42.9155 28.6923 42.9156 29.2376C42.9157 29.7828 42.8084 30.3227 42.5997 30.8264C42.3911 31.3301 42.0853 31.7878 41.6997 32.1732C41.3141 32.5587 40.8563 32.8644 40.3526 33.0729L36.7921 34.5478C35.7753 34.9682 34.9671 35.7752 34.5452 36.7914L33.0693 40.3546C32.6481 41.3715 31.8402 42.1794 30.8234 42.6006C29.8065 43.0218 28.664 43.0218 27.6472 42.6006L24.0866 41.1257C23.0698 40.7055 21.9277 40.7064 20.9114 41.1281L17.3483 42.6019C16.3321 43.0221 15.1905 43.0218 14.1745 42.6009C13.1585 42.1801 12.3511 41.3731 11.9296 40.3573L10.4533 36.793C10.0329 35.7762 9.22587 34.968 8.20974 34.5461L4.64666 33.0701C3.63025 32.6491 2.82261 31.8417 2.40127 30.8254C1.97992 29.8091 1.97935 28.6671 2.39968 27.6504L3.8745 24.0897C4.29465 23.0728 4.29379 21.9306 3.87211 20.9144L2.39941 17.3486C2.19061 16.8449 2.0831 16.3051 2.08301 15.7599C2.08292 15.2147 2.19026 14.6748 2.3989 14.171C2.60754 13.6673 2.91338 13.2097 3.29896 12.8242C3.68453 12.4387 4.14228 12.133 4.64605 11.9245L8.20657 10.4497C9.22245 10.0296 10.0302 9.22361 10.4524 8.20861L11.9283 4.6454C12.3495 3.62852 13.1574 2.82061 14.1742 2.3994C15.1911 1.97819 16.3336 1.97819 17.3504 2.3994L20.9109 3.87427C21.9278 4.29444 23.0699 4.29358 24.0862 3.87189L27.6507 2.40169C28.6674 1.98072 29.8097 1.9808 30.8264 2.40193C31.843 2.82305 32.6508 3.63073 33.072 4.64735L34.5484 8.21162L34.5479 8.20545Z"
								stroke="white"
								strokeWidth="3.0625"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					)}
				</figure>
			</div>
			<p className="text grey-white-color-white">
				{text || 'khbd jnbhdsns byhajnmajdx aghbdas'}
			</p>
			{failed && (
				<>
					<RavenButton
						onClick={onFailClick}
						className="failed-btn border-theme"
						color="black-light"
					>
						<span className="grey-white-color">
							{btnLabel || 'Retry Verification'}
						</span>
					</RavenButton>
				</>
			)}
		</div>
	);
};

export default ReuseVerified;
