import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import { toast } from '@ravenpay/raven-bank-ui';
import { CreateCardPayload, IssuingState } from './types';
import { logger } from '../services/logger';

export const getAllCards = createAsyncThunk(
	'v1/web/cards',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`/web/cards`);

			// logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setCards(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getSingleCard = createAsyncThunk(
	'web/card?card_id',
	async (payload: { card_id: string }, thunkAPI) => {
		try {
			const data = await axios.post(
				`web/card?card_id=${payload.card_id}`,
				payload
			);

			// logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				thunkAPI.dispatch(setCard(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const issueCard = createAsyncThunk(
	'/web/create_card',
	async (payload: CreateCardPayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/create_card`, payload);

			// logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				thunkAPI.dispatch(setCard(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const cardTrx = createAsyncThunk(
	'/web/card_transactions?card_id=',
	async (payload: { card_id: string }, thunkAPI) => {
		try {
			const data = await axios.post(
				`/web/card_transactions?card_id=${payload.card_id}`,
				payload
			);

			// logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				thunkAPI.dispatch(setCardTrx(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const fundCard = createAsyncThunk(
	'/web/fund_card',
	async (payload: { card_id: string; amount: string | number }, thunkAPI) => {
		try {
			const data = await axios.post(`/web/fund_card`, payload);

			// logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setCards(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
const initialState: IssuingState = {
	loading: false,
	isAuth: false,
	cards: [],
	card: {},
	trx: [],
	// initialize other state properties
};

export const issuingSlice = createSlice({
	name: 'issuing',
	initialState,

	reducers: {
		setCards: (state, action) => {
			state.cards = action.payload;
			state.isAuth = true;
		},
		setCard: (state, action) => {
			state.card = action.payload;
			state.isAuth = true;
		},

		setCardTrx: (state, action) => {
			state.trx = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getAllCards.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getAllCards.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getAllCards.rejected, () => {
			return initialState;
		});

		builder.addCase(getSingleCard.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getSingleCard.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getSingleCard.rejected, () => {
			return initialState;
		});

		builder.addCase(issueCard.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(issueCard.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(issueCard.rejected, () => {
			return initialState;
		});

		builder.addCase(cardTrx.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(cardTrx.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(cardTrx.rejected, () => {
			return initialState;
		});

		builder.addCase(fundCard.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(fundCard.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(fundCard.rejected, () => {
			return initialState;
		});
	},
});

// Action creators are generated for each case reducer function
export const { setCards, setCard, setCardTrx } = issuingSlice.actions;

export default issuingSlice.reducer;
