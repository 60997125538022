import { RavenButton, RavenInputField } from '@ravenpay/raven-bank-ui';
import React from 'react';
import ReuseVerified from '../ReusedVerified';
import { icons } from '../../../../../assets/icons';
import './styles/index.css';
import { useNavigate } from 'react-router-dom';
import { BusinessData } from '../../../../../redux/types';
import { updateBusinessInfo } from '../../../../../redux/kyc';
import { useDispatch } from 'react-redux';
import { logger } from '../../../../../services/logger';

const BusinessInformation: React.FC = () => {
	const [loading, setLoading] = React.useState(false);
	const industry: object[] = [
		{
			value: 'fintech',
			label: 'Fintech',
		},
		{
			value: 'agriculture',
			label: 'Agriculture',
		},
		{
			value: 'healthcare',
			label: 'Health Care',
		},
		{
			value: 'talent-management',
			label: 'Talent Management',
		},
		{
			value: 'all-content',
			label: 'All Content',
		},
	];

	const employeeSize: object[] = [
		{
			value: '0 - 12',
			label: '0 - 12 Employees',
		},
		{
			value: '12 - 50',
			label: '12 - 50 Employees',
		},
		{
			value: '50 - 200',
			label: '50 - 200 Employees',
		},
		{
			value: '200+',
			label: '200+ Employees',
		},
	];

	const transactionRange: object[] = [
		{
			value: '200,000 — 500,000',
			label: '₦200,000 — ₦500,000 Monthly',
		},
		{
			value: '500,000 — 5,000,000',
			label: '₦500,000 — ₦5,000,000 Monthly',
		},
		{
			value: '5,000,000 — 50,000,000',
			label: '₦5,000,000 — ₦50,000,000 Monthly',
		},
		{
			value: '50,000,000 — 200,000,000',
			label: '₦50,000,000 — ₦200,000,000 Monthly',
		},
		{
			value: '200,000,000+',
			label: 'Over 200,000,000 Monthly',
		},
	];

	const [details, setDetails] = React.useState<BusinessData>({
		business_name: '',
		business_address: '',
		business_email: '',
		business_website: '',
		business_description: '',
		industry: '',
		transaction_range: '',
		employee_size: '',
		registration_number: '',
	});

	const navigate = useNavigate();
	const dispatch = useDispatch();

	async function handleSubmit() {
		setLoading(true);
		const resp = await dispatch(updateBusinessInfo(details));
		if (resp?.payload?.status === 'success') {
			logger.log(resp);
			setLoading(false);
			navigate('/dashboard-onboarding?kyc-stage=business-info');
		} else {
			setLoading(false);
		}
	}
	return (
		<div className="business-info-verification-wrap">
			<div className="business-info-verification-wrap__title">
				<h5 className="title__big">Business Information </h5>
				<p className="title__sub">Let’s get to know you better </p>
			</div>

			<div className="business-info-verification-wrap__form">
				<div className="form__group">
					<RavenInputField
						label="Industry*"
						color="deep-green-light"
						type="select"
						onChange={(e: { value: any }) => {
							setDetails({
								...details,
								industry: e.value,
							});
						}}
						selectOption={industry}
						placeholder="Select Industry"
						style={{ width: '50%', zIndex: '200' }}
					/>
					<RavenInputField
						label="No. of Staff*"
						color="deep-green-light"
						placeholder="Select Staff Size"
						type="select"
						onChange={(e: { value: any }) => {
							setDetails({
								...details,
								employee_size: e.value,
							});
						}}
						selectOption={employeeSize}
						style={{ width: '50%', zIndex: '200' }}
					/>
				</div>

				<RavenInputField
					type="select"
					color="deep-green-light"
					selectOption={transactionRange}
					style={{ zIndex: '100' }}
					onChange={(e: { value: any }) => {
						setDetails({
							...details,
							transaction_range: e.value,
						});
					}}
					placeholder="Select Transaction Range"
					label="Monthly Transaction Range"
				/>

				<RavenInputField
					type="input"
					color="deep-green-light"
					onChange={(e: { target: { value: any } }) => {
						setDetails({
							...details,
							business_website: e.target.value,
						});
					}}
					placeholder="i.e `https://abc.com`"
					label="Business Website (Optional)"
				/>
			</div>

			<div className="business-info-verification-wrap__button">
				<RavenButton
					style={{ width: '100%' }}
					color="deep-green-light"
					loading={loading}
					disabled={
						!details.transaction_range ||
						!details.employee_size ||
						!details.industry
					}
					onClick={() => handleSubmit()}
					label="Save Business Information"
				/>
			</div>
		</div>
	);
};

export default BusinessInformation;
