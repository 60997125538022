import { TOGGLE_THEME } from './actions/actionTypes';

interface ThemeState {
	theme: string;
}

const initialState: ThemeState = {
	theme: 'light',
};

const themeReducer = (
	state: ThemeState = initialState,
	action: { type: string; payload?: any }
) => {
	switch (action.type) {
		case TOGGLE_THEME:
			return {
				...state,
				theme: action.payload,
			};
		default:
			return state;
	}
};

export default themeReducer;
