import Tesseract from 'tesseract.js';
import { useEffect, useState } from 'react';
import { Icons } from './icons';
import imgDefault from '../../../../../../assets/atlas-registration-bog-one.png';
import { RavenButton, toast } from '@ravenpay/raven-bank-ui';

interface ScreenTwoProps {
	image: string;
	onRetake: () => void;
	onScanComplete: (scanResult: Tesseract.RecognizeResult) => void;
	scanning: boolean;
	onScan?: boolean;
	onRedo?: () => void;
	onFinish?: (param?: any) => void;
	setOnScan?: (param?: any) => void;
}

export const ScreenTwo = ({
	image,
	onRetake,
	// onScanComplete,
	onScan,
  setOnScan,
	onRedo,
	onFinish,
}: // scanning,
ScreenTwoProps) => {
	const [scanning, setScanning] = useState(false);

	const scanImage = async () => {
		setScanning(true);

		const worker = await Tesseract.createWorker('eng');

		const res = await worker.recognize(image);
		// console.log(res.data.text);
		// console.log(extractNumbers(String(res.data.text)), '-----extract 1 ------');
		//  const param =  extractNumbers(String(res.data.text))
		//  const paramString = param?.join('');
		await worker.terminate();
		setScanning(false);
		// onScanComplete(res);
		const param = extractNumbers(String(res.data.text));
		const paramString = param?.join('');
		if (paramString?.length === 20) {
			// console.log(paramString);
			const obj = {
				fullValue: paramString,
				actualValue: paramString?.slice(-10),
			};
			// console.log(obj);
			onFinish && onFinish(obj);
		} else {
			toast.error("Please retake image, can't read details", {
				position: 'top-right',
			});
			onRedo && onRedo();
		}
	};

	// const

	function extractNumbers(inputString: string) {
		// Define a regular expression to match the desired pattern
		const regex = /\b(\d{20})\b/g;

		// Use matchAll to find all matches of the regex in the input string
		const matches = inputString.matchAll(regex);

		// Initialize an array to store the extracted numbers
		const numbers = [];

		// Iterate over the matches and filter out the unwanted matches
		for (const match of matches as any) {
			// Extract the number from the match
			const number = match[1];

			// Check if the number matches the desired length and contains only digits
			if (number.length === 20 && /^\d+$/.test(number)) {
				numbers.push(number);
			}
		}

		// Return the array of numbers
		return numbers;
	}

	useEffect(() => {
		if (onScan) {
			// console.log('---yes---');
			scanImage();
		}
	}, [onScan]);

	// console.log(image);

	return (
		<>
			<div className="sdk-snapped-image">
				<div className="img-div">
					<figure className="img-box">
						<img src={image} alt="" className="img" />
					</figure>
				</div>

			

				{scanning && <div className="camera-flash-scan" />}
			</div>
			{/* <div className="two-btn-box">
				<RavenButton
					color="deep-green-light"
					label="cancel"
					className="cancel-btn"
					// onClick={onCancel}
				/>
				<RavenButton
					color="deep-green-light"
					label="Proceed"
					className="procced-btn"
					onClick={scanImage}
				/>
			</div> */}
		</>
	);
};
