import {
	RavenActionBar,
	RavenButton,
	RavenInputField,
	RavenRadioCheck,
	RavenTable,
	RavenTableRow,
	RavenToggleSlide,
} from '@ravenpay/raven-bank-ui';
import React, {
	ChangeEvent,
	ReactElement,
	useEffect,
	useRef,
	useState,
} from 'react';
import { icons } from '../../../../../assets/icons';
import ColorSwatch from './colorSwatch/';
import Loader from '../../../../../components/common/loader';
import RootState, { BankboxMerchantProfile } from '../../../../../redux/types';
import Colorful from '@uiw/react-color-colorful';
import { validateEmail } from '../../../../../utils/helper/ValidateInput';
import axios from 'axios';
import { bankboxAPI } from '../../../../../redux/bankbox';
import { useDispatch, useSelector } from 'react-redux';
import { nigerianStates } from '../../data/statesInNigeria';
import { getInverseColor } from './colorConvert';
import Copy from '../../../../../components/common/copyCheck';
import { logger } from '../../../../../services/logger';
// import { FaRegEye } from 'react-icons/fa';
// import DeleteModal from '../../../../../components/common/deleteModal/DeleteModal';
import {
	capitalizeDays,
	capitalizeFirstLetter,
	formatTime,
	trimLongString,
} from '../../../../../utils/helper/Helper';
import { useOnClickOutside } from '../../../../../utils/helper/UseOnClickOutside';
// import AddLocationModal from './AddLocationModal';
// import EditLocationModal from './EditLocationModal';
// import { getSettingsConfig } from '../../../../../redux/auth';
// import { getSettingsConfig } from '../../../../../redux/settings';

function BasicProfile({
	activeSettings,
	loading,
	onChange,
	value,
	logoFile,
	logoChanged,
	onEOD,
	singleChild,
	setSingleChild,
	setRefreshTable,
	refreshTable,
	showModal,
	setShowModal,
	setImageUrl,
}: {
	loading: boolean;
	onChange: (value: any) => void;
	logoChanged: (value: boolean) => void;
	value: BankboxMerchantProfile;
	activeSettings: (e?: string) => void;
	logoFile: (e?: File) => void;
	onEOD?: () => void;
	singleChild?: any;
	setSingleChild?: (param: any) => void;
	refreshTable?: boolean;
	setRefreshTable?: (param: any) => void;
	showModal?: any;
	setShowModal?: (param: any) => void;
	setImageUrl?: (param: any) => void;
}) {
	const [isSwatchOpen, setIsSwatchOpen] = React.useState(false);
	const [cardFee, setCardFee] = React.useState(0);
	const { config } = useSelector((state: RootState) => state.settings);
	const [incomplete, setInCompleted] = React.useState<{
		1?: boolean;
		2?: boolean;
		3?: boolean;
	}>({});

	const [priceComplete, setPriceComplete] = useState<boolean>(false);
	const [webhookComplete, setWebhookComplete] = useState<boolean>(false);
	const [supportComplete, setSupportComplete] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [showDeactivate, setShowDeactivate] = useState<{
		on: boolean;
		chi: any;
	}>({
		on: false,
		chi: {},
	});
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});
	// const [singleChild, setSingleChild] = useState<any>({});
	// let appInputEl: ReactElement | any = <></>;
	const fileInputRef = useRef<HTMLInputElement>(null);
	const imgPreviewRef = useRef<HTMLImageElement>(null);
	const { all_locations, partner_settlement_info } = useSelector(
		(state: RootState) => state.bankbox
	);

	const handleUploadClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		setImageToUpload(file as any);

		logoFile(file);
		if (file && imgPreviewRef.current) {
			const reader = new FileReader();
			logoChanged(true);
			reader.onload = (e) => {
				if (imgPreviewRef.current) {
					imgPreviewRef.current.src = e.target?.result as string;
				}
			};

			reader.readAsDataURL(file);
		}
	};

	// React.useEffect(() => {
	// 	return () => {
	// 		appInputEl = document.querySelector('.input-group__color-swatch');

	// 		const appInputEl2 = appInputEl?.querySelector('.form-input');
	// 		// logger.log(appInputEl);
	// 	};
	// }, [appInputEl]);

	logger.log(value.card_fee, value.card_fee_cap, value.mandatory_fee);
	const [revealed, setRevealed] = useState(false);

	function markComplete() {
		if (
			String(value.card_fee_cap).length >= 1 &&
			String(value.card_fee).length >= 1 &&
			String(value.device_price).length >= 1 &&
			String(value.mandatory_fee).length >= 1
		) {
			setPriceComplete(true);
		}

		if (validateEmail(value.support_email) && value.region.length >= 2) {
			setSupportComplete(true);
		}

		if (
			value.webhook_url &&
			value.webhook_url?.length > 3 &&
			value.webhook_secret &&
			value.webhook_secret?.length > 3 &&
			value.website &&
			value.website.length > 3
		) {
			setWebhookComplete(true);
		}
	}

	useEffect(() => {
		markComplete();
	}, [value]);

	useEffect(() => {
		setCardFee(value.card_fee);
	}, []);
	// logger.log(incomplete, 'icp');

	function formatSecrets(secret: string) {
		return secret?.length > 45
			? (secret?.slice(0, 45) as string) + '...'
			: (secret as string);
	}
	const [currentPage, setCurrentPage] = useState(1);
	type showModalProp = {
		delete: boolean;
		add: boolean;
		edit: boolean;
	};
	const [imgToUpload, setImageToUpload] = useState('');
	// const [refreshTable, setRefreshTable] = useState(false);
	// const [showModal, setShowModal] = useState<showModalProp>({
	// 	delete: false,
	// 	add: false,
	// 	edit: false,
	// });

	const editIcon = (
		<svg
			className="img"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="dull-bright-filter"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.57495 4.00001C5.26327 4.00001 4.19995 5.06334 4.19995 6.375V17.625C4.19995 18.9367 5.26327 20 6.57495 20H17.825C19.1366 20 20.2 18.9367 20.2 17.625V10.5938C20.2 10.0415 20.6477 9.59381 21.2 9.59381C21.7522 9.59381 22.2 10.0415 22.2 10.5938V17.625C22.2 20.0413 20.2412 22 17.825 22H6.57495C4.15871 22 2.19995 20.0413 2.19995 17.625V6.375C2.19995 3.95876 4.15871 2.00001 6.57495 2.00001H13.6058C14.1581 2.00001 14.6058 2.44773 14.6058 3.00001C14.6058 3.5523 14.1581 4.00001 13.6058 4.00001H6.57495Z"
				fill="black"
			/>
			<path
				className="dull-bright-filter"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.6321 1.80007C19.1005 1.33141 19.8601 1.33109 20.3289 1.79936L22.3993 3.86743C22.8683 4.33589 22.8686 5.09588 22.4 5.56474L12.6264 15.3438C12.4592 15.511 12.2464 15.6251 12.0146 15.6718L8.38661 16.4029C8.03613 16.4735 7.72694 16.1641 7.79776 15.8137L8.52994 12.1907C8.57668 11.9594 8.69058 11.747 8.8574 11.5801L18.6321 1.80007Z"
				fill="black"
			/>
		</svg>
	);

	function DropMore({ className, idx, chi }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				{/* {chi?.status === 1 && ( */}
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setShowModal &&
							setShowModal((prev: any) => {
								return { ...prev, edit: true };
							});
						setSingleChild && setSingleChild(chi);
					}}
					className="more_item"
				>
					<figure>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21 18.0002L19.9999 19.0943C19.4695 19.6744 18.7501 20.0002 18.0001 20.0002C17.2501 20.0002 16.5308 19.6744 16.0004 19.0943C15.4692 18.5154 14.75 18.1903 14.0002 18.1903C13.2504 18.1903 12.5311 18.5154 12 19.0943M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z"
								// stroke="black"
								strokeWidth="1.2"
								strokeLinecap="round"
								strokeLinejoin="round"
								className="black-white-color"
							/>
						</svg>
					</figure>
					<p>Edit Details</p>
				</div>

				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild && setSingleChild(chi);
						setShowModal &&
							setShowModal((prev: any) => {
								return { ...prev, delete: true };
							});
						console.log(chi);
					}}
					className="more_item"
				>
					<figure>
						{
							<svg
								className="img"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5"
									stroke="#FF0F00"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						}
					</figure>
					<p style={{ color: '#FF0F00' }}>Delete Station</p>
				</div>
			</div>
		);
	}

	useEffect(() => {
		dispatch(bankboxAPI.getBankboxStates({}));
	}, []);

	useEffect(() => {
		dispatch(
			bankboxAPI.getBankboxLocations({ per_page: 20, page: currentPage })
		);
	}, [refreshTable]);

	const handleFinish = () => {
		setRefreshTable && setRefreshTable(!refreshTable);
		setShowModal &&
			setShowModal((prev: any) => {
				return { ...prev, add: false, delete: false, edit: false };
			});
	};

	// useEffect(() => {
	// 	dispatch(getSettingsConfig()).then((resp: any) => {
	// 		console.log(resp, "-----");

	// 		// if (resp.payload?.status === 'success') {
	// 		// 	// setShowModal(true);
	// 		// 	console.log(resp);
	// 		// }
	// 	});
	// 	// dispatch(getSettingsConfig)
	// }, []);

	const [loadingDelete, setLoadingDelete] = useState(false);
	async function handleDeleteLocation() {
		setLoadingDelete(true);
		const resp = await dispatch(
			bankboxAPI.deleteLocation({ id: singleChild?.id })
		);

		if (resp.payload.status === 'success') {
			setSingleChild && setSingleChild({});
			setLoadingDelete(false);
			handleFinish();
		} else {
			setLoadingDelete(false);
		}
	}

	useEffect(() => {
		if (imgToUpload) {
			uploadLogoFunction();
		}
	}, [imgToUpload]);

	const uploadLogoFunction = async () => {
		const uploadData = new FormData();

		uploadData.append('business_logo', imgToUpload as any);
		const resp = await dispatch(bankboxAPI.uploadLogo(uploadData));
		if (resp?.payload?.status === 'success') {
			// console.log(resp);
		}
	};

	function addHashIfNeeded(str: any) {
		if (/\d/.test(str) && !/^#/.test(str)) {
			return '#' + str;
		} else {
			return str;
		}
	}

	const [settleVal, setSettleVal] = useState(true);

	const testingSettingPreviewRef = useOnClickOutside(() => {
		setShowDrop({
			show: false,
			id: '',
		});
	});

	return (
		<>
			<div
				onClick={() => {
					// console.log(value);
				}}
				className="basic-details-settings"
			>
				{loading ? (
					<Loader />
				) : (
					<>
						<div
							id="basic"
							onClick={() => activeSettings('basic')}
							className="basic-details-settings__app-name"
						>
							<figure
								style={{ cursor: 'pointer' }}
								// onClick={handleUploadClick}
								className="basic-details-settings__app-name--avatar"
							>
								<div onClick={handleUploadClick} className="edit-icon-box">
									<figure className="img-box">{editIcon}</figure>
								</div>
								<input
									ref={fileInputRef}
									type="file"
									accept="image/*"
									style={{ display: 'none' }}
									onChange={handleFileChange}
								/>
								{value?.logo_url ? (
									<>
										<img
											onClick={handleUploadClick}
											ref={imgPreviewRef}
											src={
												imgToUpload
													? URL.createObjectURL(imgToUpload as any)
													: value?.logo_url ??
													  'https://sm.ign.com/ign_nordic/cover/a/avatar-gen/avatar-generations_prsz.jpg'
											}
											alt=""
										/>
									</>
								) : (
									<>
										<svg
											width="64"
											height="64"
											viewBox="0 0 64 64"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle cx="32" cy="32" r="32" fill="#CCCCCC" />
											<path
												d="M37.0909 25.3462C37.0909 28.0668 34.8116 30.4999 32 30.4999C29.1884 30.4999 26.9091 28.0668 26.9091 25.3462C26.9091 22.6257 29.1884 20 32 20C34.8116 20 37.0909 22.6257 37.0909 25.3462Z"
												fill="#F7F7F7"
											/>
											<path
												d="M44 38.3705C44 41.4797 40.3636 44.0002 32 44.0002C23.6364 44.0002 20 41.4797 20 38.3705C20 35.2613 25.3726 32.7408 32 32.7408C38.6274 32.7408 44 35.2613 44 38.3705Z"
												fill="#F7F7F7"
											/>
											<path
												d="M37.0909 25.3462C37.0909 28.0668 34.8116 30.4999 32 30.4999C29.1884 30.4999 26.9091 28.0668 26.9091 25.3462C26.9091 22.6257 29.1884 20 32 20C34.8116 20 37.0909 22.6257 37.0909 25.3462Z"
												stroke="#EEEEEE"
												strokeWidth="1.8"
											/>
											<path
												d="M44 38.3705C44 41.4797 40.3636 44.0002 32 44.0002C23.6364 44.0002 20 41.4797 20 38.3705C20 35.2613 25.3726 32.7408 32 32.7408C38.6274 32.7408 44 35.2613 44 38.3705Z"
												stroke="#EEEEEE"
												strokeWidth="1.8"
											/>
										</svg>
									</>
								)}

								<p>
									Click on the camera icon icon the image to change Business
									logo
								</p>
							</figure>

							<div className="basic-details-settings__app-name--input-group">
								<RavenInputField
									placeholder="e.g Sharons's Hair"
									color="deep-green-light"
									value={value.app_name}
									onChange={onChange}
									name="app_name"
									label="App Name"
									disabled={true}
								/>

								<div className="input-group__color-swatch">
									<div
										className="input-group__color-swatch--color-preview"
										style={{
											backgroundColor: addHashIfNeeded(value.color_code),
										}}
									>
										<p style={{ color: getInverseColor(value?.color_code) }}>
											{addHashIfNeeded(value.color_code)}
										</p>
									</div>
									{/* color swatch starts here */}
									<div
										className={`more-wrap ${
											isSwatchOpen && 'show-drop swatch-drop'
										}`}
									>
										{isSwatchOpen && (
											<div
												style={{
													position: 'fixed',
													top: '0',
													height: '100%',
													width: '100%',
												}}
												onClick={() => setIsSwatchOpen(false)}
												className="open-swatch-overlay"
											></div>
										)}
										<div className="colour-pallete">
											<Colorful
												color={value.color_code}
												disableAlpha={true}
												onChange={(color: { hexa: string }) => {
													onChange({
														target: {
															name: 'color_code',
															value: color.hexa,
														},
													});
													// dispatch(setBrandColor(color.hexa));
												}}
											/>

											<div className="color-input">
												<RavenInputField
													value={value.color_code}
													onChange={onChange}
													name="color_code"
													color="deep-green-light"
													label="Colour Code"
												/>
											</div>
										</div>
									</div>

									<div
										onClick={() => setIsSwatchOpen(!isSwatchOpen)}
										className="input-group__color-swatch--dropdown"
									>
										<figure>{icons.chevron_down}</figure>
									</div>
								</div>
							</div>
							<RavenInputField
								// style={{ opacity: '0' }}
								value={value.website}
								onChange={onChange}
								name="website"
								color="deep-green-light"
								placeholder="e.g www.laura.com"
								label="Website *"
							/>
						</div>

						<div
							id="settle"
							onClick={() => activeSettings('settle')}
							className="basic-details-settings__prices"
						>
							<div className="title-button-box">
								<div className="title-box">
									<h6 className="basic-details-settings__prices--title">
										Settlement Account
									</h6>
								</div>
								<p
									onClick={() => {
										setShowModal &&
											setShowModal((prev: any) => {
												return { ...prev, edit_settle: true };
											});
									}}
									className="edit-text"
								>
									{Object?.keys(partner_settlement_info)?.length < 1 ? `ADD` : `EDIT`}
								</p>
							</div>

							<RavenActionBar
								className="basic-details-settings__prices--actionbar"
								visible={settleVal}
								onCancel={() => {
									setSettleVal(false);
								}}
								type={'warning'}
								msg={
									'This is your own account that your payment would be settled to '
								}
							/>

							<div
								className={`basic-details-settings__prices--input-group ${
									settleVal && 'incomplete'
								}`}
							>
								<div className="inputs">
									<RavenInputField
										placeholder=" "
										color="deep-green-light"
										value={partner_settlement_info?.bank}
										onChange={onChange}
										type="text"
										numberPrefix={`₦`}
										label="Select Bank* "
										disabled
									/>
									<RavenInputField
										value={partner_settlement_info?.account_name}
										onChange={(e: any) => {
											// Number(e.target.value) <= 100 && setCardFee(e.target.value);
											Number(e.target.value) <= 100 && onChange(e);
										}}
										disabled
										name="card_fee"
										color="deep-green-light"
										// thousandFormat
										placeholder=" "
										type="text"
										label="Account Name"
									/>
								</div>
								<div className="inputs">
									<RavenInputField
										color="deep-green-light"
										label="Account Number* "
										value={partner_settlement_info?.account_number}
										onChange={onChange}
										placeholder=" "
										// thousandFormat
										type="account-number"
										// numberPrefix={`₦`}
										name="card_fee_cap"
										disabled
									/>

									{/* <RavenInputField
										color="deep-green-light"
										value={value.mandatory_fee}
										onChange={onChange}
										type="number"
										thousandFormat
										numberPrefix={`₦`}
										name="mandatory_fee"
										label="Mandatory Fee*"
									/> */}
								</div>
							</div>
						</div>

						<div
							id="prices"
							onClick={() => activeSettings('prices')}
							className="basic-details-settings__prices"
						>
							<h6 className="basic-details-settings__prices--title">
								Prices, Rates and Fees
							</h6>

							<RavenActionBar
								className="basic-details-settings__prices--actionbar"
								visible={!priceComplete}
								onCancel={() => {
									setPriceComplete(true);
								}}
								type={'warning'}
								msg={'This setup is incomplete at the moment'}
							/>

							<div
								className={`basic-details-settings__prices--input-group ${
									!priceComplete && 'incomplete'
								}`}
							>
								<div className="inputs">
									<RavenInputField
										placeholder="e.g ₦30,000"
										color="deep-green-light"
										value={value.device_price}
										onChange={onChange}
										name="device_price"
										thousandFormat
										type="number"
										numberPrefix={`₦`}
										label="Device Price*"
									/>
									<RavenInputField
										color="deep-green-light"
										label="Amount Capped*"
										value={value.card_fee_cap}
										onChange={onChange}
										thousandFormat
										type="number"
										numberPrefix={`₦`}
										name="card_fee_cap"
									/>
								</div>
								<div className="inputs">
									<RavenInputField
										value={value.card_fee}
										onChange={(e: any) => {
											// Number(e.target.value) <= 100 && setCardFee(e.target.value);
											Number(e.target.value) <= 100 && onChange(e);
										}}
										name="card_fee"
										color="deep-green-light"
										thousandFormat
										placeholder="e.g 5"
										type="text"
										label="Rate %*"
									/>
									<RavenInputField
										color="deep-green-light"
										value={value.mandatory_fee}
										onChange={onChange}
										type="number"
										thousandFormat
										numberPrefix={`₦`}
										name="mandatory_fee"
										label="Mandatory Fee*"
									/>
								</div>
							</div>
						</div>

						<div
							id="operations"
							onClick={() => activeSettings('operations')}
							className="basic-details-settings__prices"
						>
							<h6 className="basic-details-settings__prices--title">
								Operations and Support
							</h6>

							<RavenActionBar
								className="basic-details-settings__prices--actionbar"
								visible={!supportComplete}
								onCancel={() => {
									setSupportComplete(true);
								}}
								type={'warning'}
								msg={'This setup is incomplete at the moment'}
							/>

							<div
								className={`basic-details-settings__prices--input-group ${
									!supportComplete && 'incomplete'
								}`}
							>
								<div className="inputs">
									<RavenInputField
										value={value.support_email}
										onChange={onChange}
										name="support_email"
										color="deep-green-light"
										placeholder="e.g bankbox.support@riggs.com"
										label="Support Email*"
									/>
								</div>

								<div className="inputs">
									{/* <RavenInputField
									color="deep-green-light"
									value={value.app_id}
									onChange={onChange}
									name="app_name"
									label="Operating Price*"
								/> */}
									<RavenInputField
										style={{ zIndex: 10000 }}
										value={{
											value: value.region,
											label: value.region,
										}}
										onChange={(e: { label: any }) => {
											onChange({
												target: {
													name: 'region',
													value: e.label,
												},
											});
										}}
										type="select"
										name="region"
										selectOption={nigerianStates}
										placeholder="e.g Portharchout"
										color="deep-green-light"
										label="Region*"
									/>
									{/* <RavenInputField
									style={{ opacity: '0' }}
									value={value.website}
									onChange={onChange}
									name="website"
									color="deep-green-light"
									placeholder="e.g www.laura.com"
									label="Website *"
								/> */}
								</div>
							</div>
						</div>

						<div
							id="webhook"
							onClick={() => {
								// console.log(config);

								activeSettings('webhook');
							}}
							className="basic-details-settings__prices"
						>
							<h6 className="basic-details-settings__prices--title">
								Webhook & Keys
							</h6>

							<RavenActionBar
								className="basic-details-settings__prices--actionbar"
								visible={!webhookComplete}
								onCancel={() => {
									setWebhookComplete(true);
								}}
								type={'warning'}
								msg={'This setup is incomplete at the moment'}
							/>

							<div
								className={`basic-details-settings__prices--input-group ${
									!webhookComplete && 'incomplete'
								}`}
							>
								<div className="inputs">
									<RavenInputField
										value={value.webhook_url}
										onChange={onChange}
										name="webhook_url"
										placeholder="e.g. https://api.riggs.com/v1/webhook"
										color="deep-green-light"
										label="Webhook URL*"
									/>
								</div>

								<div className="inputs">
									<RavenInputField
										value={value.webhook_secret}
										onChange={onChange}
										name="webhook_secret"
										color="deep-green-light"
										placeholder="Enter your webhook secret"
										label="Webhook Secret*"
									/>
								</div>
							</div>

							<div
								className={``}
								style={{
									width: '100%',
									display: 'grid',
									gridTemplateColumns: 'repeat(2, 1fr)',
									gridGap: '2rem',
								}}
							>
								<div className="grouped__input">
									<RavenInputField
										label={'App ID'}
										type="text"
										readOnly
										color="deep-green-light"
										placeholder={value?.app_id}
										value={value?.app_id}
										disabled={true}
									/>
									<Copy
										icon={icons.copy_black}
										className="input__copy-icon"
										text={value?.app_id}
									/>
								</div>
								<div className="grouped__input">
									<RavenInputField
										label={'Live Secret Key'}
										type="text"
										readOnly
										color="deep-green-light"
										placeholder={config?.live_secret_key}
										value={trimLongString(config?.live_secret_key, 30)}
										disabled={true}
									/>
									<Copy
										icon={icons.copy_black}
										className="input__copy-icon"
										text={config?.live_secret_key}
									/>
								</div>
							</div>
						</div>

						{/*  */}
						<div
							id="eod"
							onClick={() => activeSettings('eod')}
							className="basic-details-settings__prices"
						>
							<h6 className="basic-details-settings__prices--title">
								End of Day Report
							</h6>

							<RavenActionBar
								className="basic-details-settings__prices--actionbar"
								visible={!webhookComplete}
								onCancel={() => {
									setWebhookComplete(true);
								}}
								type={'warning'}
								msg={'This setup is incomplete at the moment'}
							/>

							<div
								className={`basic-details-settings__prices--input-group ${
									!webhookComplete && 'incomplete'
								}`}
							>
								<div className="basic-details-settings__generate-eod">
									<p>Generate End of Day Report</p>
									<RavenButton
										onClick={() => {
											onEOD && onEOD();
										}}
										color="deep-green-light"
										label="Generate Report"
									/>
								</div>
							</div>

							<div className="grouped__input">
								<div className="basic-details-settings__generate-eod">
									<p>Send emails to all merchants at the end of the day.</p>
									<RavenToggleSlide
										// onClick={() => {}}
										onChange={() => {}}
										color="deep-green-light"
									/>
								</div>
							</div>
						</div>
						<div
							id="pickup"
							onClick={() => {
								activeSettings('pickup');
								// console.log(all_locations);
							}}
							className="basic-details-settings__prices"
						>
							<div className="title-button-box">
								<div className="title-box">
									<h6 className="basic-details-settings__prices--title">
										Pick up stations
									</h6>
									<p className="text grey-white-color">
										Add all the necessary station customers can pick up from.
									</p>
								</div>
								<RavenButton
									color="deep-green-light"
									label="Add a pick station"
									onClick={() => {
										setShowModal &&
											setShowModal((prev: any) => {
												return { ...prev, add: true };
											});
									}}
								/>
							</div>

							{/* table box start */}
							<div className="table-pickup">
								<RavenTable
									headerList={[
										'PICK UP STATION',
										'DURATION',
										'STATE',
										'ACTION',
									]}
								>
									{all_locations.map((chi: any, idx: any) => {
										return (
											<RavenTableRow
												// onRowClick={() => {
												// 	console.log(chi);
												// }}
												key={idx}
												one={trimLongString(chi?.address, 23) || '---'}
												two={`${
													chi?.pick_up_days?.length > 0
														? capitalizeDays(chi?.pick_up_days)
														: ''
												} ${formatTime(chi?.start_time)}-${formatTime(
													chi?.stop_time
												)}`}
												// two={``}
												three={capitalizeFirstLetter(chi?.state)}
												four={
													<div
														style={{ position: 'relative' }}
														onClick={() =>
															setShowDrop((prev) => {
																return {
																	...prev,
																	id: idx,
																	show:
																		showDrop?.id === idx
																			? !showDrop.show
																			: true,
																};
															})
														}
														// ref={testingSettingPreviewRef}
													>
														<DropMore
															idx={idx}
															className={isDropped(idx) && 'show-drop'}
															chi={chi}
														/>
														{icons.three_dots}
													</div>
												}
											/>
										);
									})}
								</RavenTable>
							</div>
							{/* table box end */}
						</div>
					</>
				)}
			</div>
			{/* modal start */}
		</>
	);
}

export default BasicProfile;
