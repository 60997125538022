import React, { ReactNode } from 'react';
import './styles/index.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MobileTableCard = ({
	title,
	text,
	amount,
	img,
	avatar,
	amountColor,
	type,
	showImg,
	loading,
	onRowClick,
	textTwo,
	bottomRightText,
}: {
	title: string;
	text: string;
	amount: number | string;
	img: string | undefined;
	svg: ReactNode;
	avatar: string;
	amountColor: string;
	type: string | number;
	showImg: boolean;
	loading: boolean;
	bottomRightText?: string;
	onRowClick: (row?: any) => void | Function;
	textTwo?: string;
}) => {
	const formatToAvatar = (str: string) => {
		if (str) {
			const valOne = str?.split(' ')[0]?.charAt(0);
			const valTwo = str?.split(' ')[1]?.charAt(0);
			return `${valOne || ''}${valTwo || ''}`;
		}
	};

	return (
		<div
			onClick={() => {
				onRowClick && onRowClick();
			}}
			className="mobile-table-card-wrapper-box border-theme"
		>
			{/* avatar img box start */}
			{showImg ? (
				<div className="avatar-img-box">
					{loading ? (
						<Skeleton width={30} height={30} circle />
					) : (
						<>
							{avatar && (
								<>
									<div className="avatar-box grey-bg">
										<span className="grey-white-color-white">
											{formatToAvatar(avatar)}
										</span>
									</div>
								</>
							)}
							{img && (
								<figure className="img-box">
									<img src={img} alt="" />
								</figure>
							)}
							{type && (
								<figure
									className={`img-box img-box-cre-deb  ${
										type === 'debit' && 'img-box-debit'
									} img-box-debit-credit`}
								>
									{' '}
									{type === 'credit' ? (
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											className="img"
										>
											<path
												d="M9 1L1 9M1 9H6.33333M1 9V3.66667"
												stroke="#1ACE37"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									) : (
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											className="img"
										>
											<path
												d="M1 9L9 1M9 1H3.66667M9 1V6.33333"
												stroke="#FF0F00"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									)}
								</figure>
							)}
						</>
					)}
				</div>
			) : (
				''
			)}
			{/* avatar img box end */}
			{/* title- text box start */}
			<div className="title-text-box">
				{loading ? (
					<>
						<Skeleton width={90} height={12} />
						<Skeleton width={150} height={9} />
					</>
				) : (
					<>
						{title && <p className="title">{title}</p>}
						{text && <p className="text grey-white-color">{text}</p>}
						{textTwo && <p className="text grey-white-color">{textTwo}</p>}
					</>
				)}
			</div>
			{/* title- text box end */}
			{/* amount start */}
			{amount && (
				<div className="amount-box">
					{loading ? (
						<Skeleton width={60} height={12} />
					) : (
						<p
							style={{ color: amountColor && amountColor }}
							className={`amount`}
						>
							{amount}
						</p>
					)}

					{loading ? (
						<Skeleton width={60} height={12} />
					) : (
						<p
							// style={{ color: amountColor && amountColor }}
							className={`text grey-white-color`}
						>
							{bottomRightText}
						</p>
					)}
				</div>
			)}
			{/* amount end */}
		</div>
	);
};

export default MobileTableCard;
