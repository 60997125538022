interface CustomWindow extends Window {
	attachEvent: any;
	intercomSettings: any;
}

declare var window: CustomWindow;

export const livechat = (): void => {
	const script = document.createElement('script');
	script.src = `//fw-cdn.com/10934263/3694369.js?channelId=${'channelId'}`;
	script.setAttribute('chat', 'true');
	// Add an event listener to check when the script has loaded
	script.onload = () => {
		// Now that the script has loaded, you can use its functionality here.
		// For example, you can interact with the chat system.
	};
	// Append the script to the document's body
	document.body.appendChild(script);
};
// export const mjgh = {};