import { RavenInputField, RavenModal } from '@ravenpay/raven-bank-ui';
import React, { useState } from 'react';

interface MyComponentProps {
	children?: React.ReactNode;
	visible: boolean;
	onClose: () => {};
  setStep: () => {};
}

const ConfirmationPinModal: React.FC<MyComponentProps> = ({ visible, onClose, setStep }) => {
	type TeamPinProp = {
		pin: string;
	};

	type TeamPinCompleteProp = {
		complete: boolean;
	};

	const [details, setDetails] = useState<TeamPinProp>({
		pin: '',
	});

	const [completeToken, setcompleteToken] = useState<TeamPinCompleteProp>({
		complete: false,
	});

	const handleSubmit = () => {};

	return (
		<RavenModal
			onClose={onClose}
			visble={visible}
			// loading={loading}
			btnLabel="Verify Account"
			btnColor={`deep-green-light`}
			effect={`fadeInUp`}
			closeColor
			disabled={!completeToken?.complete}
			onBtnClick={handleSubmit}
			className={`auth-pin-modal-wrap`}
		>
			<form
				onSubmit={(e) => {
					e.preventDefault();
				}}
				action=""
				className="form form-modal-auth"
			>
				<div className="text-box">
					<p className="big-title">Authenticatio PIN</p>
					<p className="small-title">
						Please kindly provide the 6-Digit Authentication PIN to complete
						registration
					</p>
				</div>
				<RavenInputField
					type={`pin`}
					color={`black-light`}
					onChange={(e: any) => {
						setcompleteToken((prev) => {
							return { ...prev, complete: false };
						});
						setDetails((prev) => {
							return { ...prev, pin: e };
						});
					}}
					onComplete={(pin: number) => {
            setStep()
						setcompleteToken((prev) => {
							return { ...prev, complete: true };
						});
						// handleSubmitDirect(pin);
					}}
					value={details?.pin}
					showCountDown
					// countDownTime={50}
					key={''}
					countdownFinishClick={() => {
						('');
					}}
					countdownFinishText={` Resend OTP`}
					countdownFinishClassName={`resend-text`}
					// onCountdownFinish
					countdownLabelClassName={`black-white-color`}
				/>
			</form>
		</RavenModal>
	);
};

export default ConfirmationPinModal;
