import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth';
import thunk from 'redux-thunk';
import themeReducer from './theme';
import thunkMiddleware from 'redux-thunk';
import * as reduxThunk from 'redux-thunk/extend-redux';
import { walletSlice } from './wallet';
import { transactionSlice } from './transaction';
import { settingSlice } from './settings';
import { billsSlice } from './bills';
import { kycSlice } from './kyc';
import generalReducer from './general';
import { issuingSlice } from './issuing';
import { bankboxSlice } from './bankbox';

// create a makeStore function
const makeStore = () =>
	configureStore({
		reducer: {
			auth: authSlice.reducer,
			issuing: issuingSlice.reducer,
			wallet: walletSlice.reducer,
			transaction: transactionSlice.reducer,
			settings: settingSlice.reducer,
			bills: billsSlice.reducer,
			kyc: kycSlice.reducer,
			bankbox: bankboxSlice.reducer,
			general: generalReducer,
			theme: themeReducer, // Access the reducer generated by createSlice
		},
		middleware: (getDefaultMiddleware: any) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActionPaths: [
						// 'payload.headers',
						// 'payload.config.transformRequest',
					],
				},
			}).concat(thunk, thunkMiddleware),
	});

const store = makeStore();

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
