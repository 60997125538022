import { RavenInputField, RavenModal, toast } from '@ravenpay/raven-bank-ui';
import React, { useState } from 'react';
import '../fee-details/style/CreateFeeCategoryModal.css';
import {
	checkDisputeStatus,
	checkDisputeStatusColor,
	formatNumWithoutCommaNaira,
	returnStatus,
} from '../../../../utils/helper/Helper';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../redux/types';
import { bankboxAPI } from '../../../../redux/bankbox';
import BadgeComponent from '../../../../components/common/badge/BadgeComponent';

interface MyComponentProps {
	visible?: boolean;
	onClose?: () => void;
	onFinish?: () => void;
	detail?: any;
}

const StatusUpdateModal: React.FC<MyComponentProps> = ({
	visible,
	onClose,
	onFinish,
	detail,
}) => {
	const [step, setStep] = useState(1);
	const stepList = [1, 2];
	const { profile } = useSelector((state: RootState) => state.wallet);
	const { loading_fee } = useSelector((state: RootState) => state.bankbox);
	const dispatch = useDispatch();
	interface formComponent {
		fee_category: string;
		rate: string;
		cap: string;
		transfer_fee: string;
		collection_amount: string;
		collection_fee: string;
		fee: string;
		status: any;
		message: string;
	}
	const [details, setDetails] = useState<formComponent>({
		fee_category: '',
		rate: '',
		cap: '',
		transfer_fee: '',
		collection_amount: '',
		collection_fee: '',
		fee: '',
		status: '',
		message: '',
	});

	// const [showError, setShowError] = useState(false);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const selectOptions = [
		{ label: 'Review', value: 'review' },
		{ label: 'Resolve', value: 'resolve' },
		{ label: 'Reject', value: 'discard' },
	];

	const [sendLoading, setSendLoading] = useState(false);
	const handleSubmitStatusChange = async () => {
		const obj = {
			email: detail?.email,
			action: details?.status?.value,
			reason: details?.message,
			id: String(detail?.id),
		};

		// console.log(obj);
		// return;

		setSendLoading(true);
		const data = await dispatch(bankboxAPI.changeDisputeStatus(obj as any));
		// console.log(data);
		if (data?.payload?.status === 'success') {
			setSendLoading(false);
			// setActiveContent('Dispute Details');
			// onCancel && onCancel();
			onFinish && onFinish();
			setDetails((prev) => {
				return { ...prev, message: '', title: '' };
			});
		} else {
			setSendLoading(false);
		}
	};

	const infoIcon = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_291_8854)">
				<path
					d="M6.06016 5.99967C6.2169 5.55412 6.52626 5.17841 6.93347 4.9391C7.34067 4.69978 7.81943 4.6123 8.28495 4.69215C8.75047 4.772 9.17271 5.01402 9.47688 5.37536C9.78106 5.7367 9.94753 6.19402 9.94683 6.66634C9.94683 7.99967 7.94683 8.66634 7.94683 8.66634M8.00016 11.333H8.00683M14.6668 7.99967C14.6668 11.6816 11.6821 14.6663 8.00016 14.6663C4.31826 14.6663 1.3335 11.6816 1.3335 7.99967C1.3335 4.31778 4.31826 1.33301 8.00016 1.33301C11.6821 1.33301 14.6668 4.31778 14.6668 7.99967Z"
					stroke="#EA872D"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_291_8854">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);

	const getStatusText = (param: number) => {
		// 2: discard; 3: review; 4: resolve 0: pending;
		const val =
			String(param) === '0'
				? 'pending'
				: String(param) === '2'
				? 'discard'
				: String(param) === '3'
				? 'review'
				: String(param) === '4'
				? 'resolve'
				: 'pending';
		return val;
	};

	return (
		<RavenModal
			btnColor="deep-green-light"
			btnLabel={`Update status`}
			loading={sendLoading}
			onBtnClick={handleSubmitStatusChange}
			onClose={() => {
				setStep(1);
				onClose && onClose();
			}}
			visble={visible}
			className={`session-expired-wrap-modal`}
			disabled={!details?.status}
		>
			<div className="create-fee-category-modal-wrap-index">
				{/* title text start */}
				<div className="title-text-box">
					<p className="title">Update dispute status</p>
					<p className="text grey-white-color">Some subtext for the modal</p>
				</div>
				{/* title text end */}
				{/* step content box start */}
				<div className="step-content-wrap step-content-wrap-no-height">
					{/* badge info start */}
					<div className="badge-info-box">
						<div className="bagde-box grey-bg">
							<span>{detail?.email || '---'}</span>
						</div>
						<div className="info-box">
							<figure className="img-box">{infoIcon}</figure>
						</div>
					</div>
					{/* badge info end */}
					<div className="value-label-wrap-index-box">
						{/* value label start */}
						<div
							style={{ paddingBottom: '1.5rem' }}
							className="label-value-box border-theme-bottom"
						>
							<p className="label">Dispute Status</p>
							<div className="value">
								<BadgeComponent
									className={checkDisputeStatusColor(detail?.status)}
									text={checkDisputeStatus(detail?.status)}
								/>
							</div>
						</div>
						{/* value label end */}
					</div>
					{/* step one start */}
					{step === 1 && (
						<>
							<RavenInputField
								placeholder=" "
								label="Status"
								type="select"
								color="deep-green-light"
								value={details?.status}
								name={`status`}
								onChange={(e: any) => {
									setDetails((prev) => {
										return { ...prev, status: e };
									});
								}}
								selectOption={selectOptions}
								style={{ position: 'relative', zIndex: 100 }}
							/>

							<RavenInputField
								placeholder="Enter reason"
								label="Reason"
								type="textarea"
								color="deep-green-light"
								value={details?.message}
								name={`message`}
								onChange={handleChange}
							/>
						</>
					)}
					{/* step one end */}
				</div>
				{/* step content box end */}
			</div>
		</RavenModal>
	);
};

export default StatusUpdateModal;
